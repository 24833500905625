import React from 'react';
import taskLogo from "./tasks_icons_android/circle_like_android.svg";
import BaseTask from './BaseTaskAndroid';
import check from "./tasks_icons_android/check.svg";
import { openAppWithParams } from './insta_custom_views/utils_android/AndroidMethods';
import { getGoogleId } from './insta_custom_views/utils_android/CookieHelper';
import sdk_appeal from '../icons_android/sdk_appeal.svg'

class SdkTaskAndroid extends BaseTask {
   onResume(){
        const isReady = this.props.index == 0 ? true : (this.props.sdkTasks[this.props.index - 1].state == 1)

        console.log("this.props.sdkTask.state = " + this.props.sdkTask.state)
        console.log("this.startTaskTime = " + this.startTaskTime)
        console.log("isReady = " + isReady)
        console.log("onResume SdkTaskAndroid")
        //if (this.startTaskTime != -1){
            console.log("onResume getOrder")
            this.props.getOrder()
        //     this.startTaskTime = -1
        // }
        
   }
    constructor(props){
        super(props)
        this.startTaskTime = -1
        this.state = {
            btn : 'Сделать'
        }
        window.sdkTask = this
        this.type = 6
    }

    onButtonClick = () => {
        this.startTaskTime = new Date().getTime() / 1000
        // console.log("sdk task click bundle = " + this.props.task.bundle)
        // console.log("sdk task click getGoogleId() = " + getGoogleId())
        // console.log("sdk task click _id = " + this.props.task._id)
        openAppWithParams(this.props.task.bundle, getGoogleId(), this.props.task._id)
    }
    
    openAppeal = () => {
        this.props.toggleSdkAppeal(true, {task_id : this.props.sdkTask._id, text : this.props.sdkTask.description})
    }
    render(){
            const isTaskFinished = this.props.sdkTask.state == 1
            const isReady = this.props.isEnabled
            return (
                <div className={isTaskFinished ? 'finished_task_bg' : isReady ? 'block' : 'not_ready'}>
                    <div className="flex padding_16">
                        {isTaskFinished ? 
                            <div className="img_icon_container">
                                <img width="40" height="40" className="icon_task_type" src={check}/>
                            </div> :
                            <div className="sdk_task_counter_cont">
                                <p className="sdk_task_counter">{(this.props.index + 1) + "/" + (this.props.sdkTasks.length)}</p>
                            </div>
                        }
                        
                        <div className="block padding_left_16">
                            <p className="task_name" dangerouslySetInnerHTML={{__html: this.props.sdkTask.name}}/>
                            <p style={{opacity : 0.7, fontSize : '12px'}} className="bot_30p" dangerouslySetInnerHTML={{__html: this.props.sdkTask.description}}/>
                            <div className="flex justify_content">
                                <button onClick={this.onButtonClick} disabled={(!isReady || isTaskFinished)}
                                className={isTaskFinished ? 'finished_btn' : 'task_btn'}>{isTaskFinished ? "Выполнено" : "Сделать"}</button>
                                <p className="text_task_money">+{this.props.sdkTask.price}₽</p>
                            </div>
                            <img onClick={this.openAppeal} disabled={isTaskFinished} className="sdk_appeal_btn" src={sdk_appeal}/>
                            
                        </div>

                    </div>
                    <div className="line"></div>
             </div>

            ); 
        
        
    }
}
export default SdkTaskAndroid;