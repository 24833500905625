import React from 'react';
import empty_video_img_insta from './insta_icons_tasks/empty_video_img_insta.svg'
import video_watch_insta_android from './insta_icons_tasks/video_watch_insta_android.svg'
import video_insta_android from './insta_icons_tasks/video_insta_android.svg'
import chevron_next_insta_android from './insta_icons_tasks/chevron_next_insta_android.svg'
import BaseEarnTask from './BaseEarnTask';
import qs from 'querystring'
import {getRequestParams} from './utils_android/CookieHelper'
import { showAndroidToast } from './utils_android/AndroidMethods';
import { ReCaptcha } from 'react-recaptcha-v3'

class ExtraView extends BaseEarnTask {
    constructor(props){
        super(props)
        this.state = {
            currentDescription : "",
            isImgError : false,
            isUpdateCaptcha : false
        }
        this.startTaskTime = -1
        window.extraView = this
        this.toogleImgError = this.toogleImgError.bind(this);
    }
   
    toogleImgError(){
        this.setState({isImgError : true})
    }
    onResume(){
        if (this.startTaskTime != -1){
            var currentTime = new Date().getTime() / 1000
            if (currentTime - 7 >= this.startTaskTime){
                // if (this.props.isNeedInstaCaptcha){
                //     this.setState({isUpdateCaptcha : true})
                // } else {
                //     this.verifyCallback()
                   
                // }
                 this.instaViewVideo()
            } else {
                showAndroidToast("Вернитесь и посмотрите видео минимум 7 секунд.")
            }
        }
    }
    verifyCallback = (recaptchaToken) => {
        this.setState({isUpdateCaptcha : false})
        this.props.showLoader()
        this.instaViewVideo(recaptchaToken)
    }
   
   
    doTask = () => {
        this.startTaskTime = new Date().getTime() / 1000
        this.openUrl(this.props.currentExtraOrder.insta_url)
    }
    instaViewVideo = (recaptchaToken) => {
        this.props.showLoader()
        var params = getRequestParams();
        params._id = this.props.currentExtraOrder.order._id;
        if (recaptchaToken != undefined){
            params.timestamp = recaptchaToken
        }
        console.log("instaViewVideo")
        fetch("https://app-coins.ru/api/instaViewVideo", {
            method: 'POST',
            headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
            body: qs.stringify(params)
        }).then((response) => response.json())
        .then((responseData) => {
            if (responseData.response == 1 || responseData.response == 2) {
                this.props.loadNextOrder()
            } else {
                this.errorTask()
            }
            this.startTaskTime = -1
            console.log("instaViewVideo" + JSON.stringify(responseData))
        })
    }
    skip = () => {
        this.skipOrder(false, this.props.currentExtraOrder.order._id)
    }
  
    render() {
        return (
            <div className="rate-content__item">
                    <div className="photo_task_container">
                        <img width="64" height="64" className="placeholder_task icon_64"
                            src={empty_video_img_insta} alt=""/>
                        <img style={this.state.isImgError ? {opacity : '0'} : {}} onError={this.toogleImgError} className="image_task_holder" src={this.props.currentExtraOrder.userInfo ? this.getSource(this.props.currentExtraOrder.userInfo.items[0]) : this.props.currentExtraOrder.order.photo_url}
                            alt="" width="360" height="360"/>
                        <img onClick={this.skip} src={chevron_next_insta_android} className="arrows__item arrows__item_next arrow_item_follower"></img>
                        <img src={video_insta_android} className="rate-content__video-icon"></img>
                        {/* <p onClick={this.appealClicked} className="appeal_text appeal_text_bg">Пожаловаться</p> */}
                    </div>


                    <div className="rate-content__actions">
                        <div onClick={this.doTask} className="btn-follow top_10">
                            <img className="btn_task_icon" src={video_watch_insta_android} alt=""/>
                            Смотреть видео
                        </div>
                        <div className="coins_rub_lashka rub_plashka">
                            <span
                                className="subscribe_follow_reward">+{this.props.currentExtraOrder.earn_money}₽</span>
                        </div>
                        
                    </div>
                    {this.state.isUpdateCaptcha && <ReCaptcha
                        sitekey="6LdFRuEUAAAAAGBfnPHHxCvPSn8bl5ISdZSLiu7_"
                        action='insta'
                        verifyCallback={this.verifyCallback}/>
                    } 
            </div>
        );
    }
}
export default ExtraView;
