import LocalizedStrings from 'react-localization'
export const getLocalizedStrings = () => {
    let strings = new LocalizedStrings({
        ru:{
           
            followersCount:"подписчики",
            goToVideo : "Перейти к ролику",
            goToAuthor : "Перейти",
            viewTaskDesc : "Посмотри ролик минимум 10 секунд",
            likeTaskDesc : "Поставить лайк",
            shareTaskDesc : "Поделиться роликом",
            shareTaskDesc2 : "Скопировать ссылку и отправить кому-то в любом мессенджере",
            commentTaskDesc : "Написать подходящий положительный комментарий",
            commentTaskDesc2 : "Комментарий должен соответствовать содержанию ролика.",
            followTaskDesc : "Подписаться на автора ролика",
            reward : "Вознаграждение начисляется только при выполнении всех действий",
            authError : "Такое имя не найдено. Проверьте правильность написания вашего username",
            taskErrorText : "Данные не всегда обновляются быстро, поэтому наша система не засекла ваше дествие. Если вы действительно выполнили задачи то, нажмите \"Проверить еще раз\", либо выполните задание снова",
            taskErrorBtn : "Проверить еще раз",
            //not localized

        }
    });
    return strings;
};
