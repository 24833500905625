import React from 'react';
class ActiveTaskItem extends React.Component {
    constructor(props) {
        super(props);
    }
    openTask = function(){
        //window.location.href = '/task?order_id=' + this.props.task.order_id + "&progress_array=" + this.props.task.progressArray + "&progress_id=" + this.props.task._id + "&modified=" + this.props.task.modified.split('T')[0] + "&back=" + (this.props.isActive ? "active" : "finished") + "&testReactJS=1"
        this.props.onTaskClicked(this.props.task, 'task', this.props.backView)
    }
    render() {
        return (
            <div onClick={() => this.openTask()}>
                <div className="flex justify_content bot_12">
                    <div className="flex">
                        <div className="icon_container">
                            <img className="tasks_icon" src={this.props.task.icon} alt=""/>
                        </div>
                        
                        <div className="block justify_content text_container">
                            <p className="task_name task_name_overflow">{this.props.task.name}</p>
                            <p className="task_description">{this.props.task.category}</p>
                        </div>
                    </div>
                    <p className="money_text">+{this.props.task.price_one_install_employer}₽</p>
                </div>
                <div className="line_full"></div>
            </div>
        );
    }
    
}
export default ActiveTaskItem;