/* eslint-disable eqeqeq */
import React from 'react';
import taskLogo from "./tasks_icons_android/circle_star_android.svg";
import BaseTask from './BaseTaskAndroid';
import qs from 'querystring'
import check from "./tasks_icons_android/check.svg";
import { AppsType, AppsUrls } from './insta_custom_views/utils_android/UtilsAndroid';


class ThirdTask extends BaseTask {
    finishTask(){
        this.setState({isFinished : true, btn : "Выполнено"})
        this.props.completedTask()
    }
   onResume(){
        if (!this.state.isFinished && (this.props.isEnabled || this.state.isEnabled) && this.startTaskTime != -1){
            var currentTime = new Date().getTime() / 1000
            if (currentTime - 5 >= this.startTaskTime){
                console.log("case 2 startPerfom")
                this.perfomReview()
            } else if (currentTime - 2 >= this.startTaskTime) {
                this.startTaskTime = -1
                this.props.showErrorResponse("Пожалуйста, вернитесь на страницу с приложением и напишите отзыв", "Написать", this.onButtonClick)
                console.log("case 2 showErrorBanner")
            }
        }

        //testing
        // if (!this.state.isFinished && (this.props.isEnabled || this.state.isEnabled)){
        //     this.finishTask()
        // }
    console.log("onResume ThirdTask this.props.isEnabled = " + this.props.isEnabled)
   }
    constructor(props){
        super(props)
        this.startTaskTime = -1
        this.order = this.props.task
        this.state = {
            isFinished : false,
            isReadyStart : false,
            btn : (this.order.review_words != "Поставьте оценку 5 звезд, отзыв НЕ пишите." ? 'Написать' : 'Оценить')
        }
        window.thirdTask = this
        this.installUrl = this.order.type_os == AppsType.android ? AppsUrls.android : AppsUrls.rustore
        this.type = 1
    }

    
    componentDidUpdate(){
        if (this.props.isEnabled && !this.state.isReadyStart){
            this.setState({isReadyStart : true})
        }
    }
    perfomReview = () => {        
        fetch("https://app-coins.ru/api/perfomReview", {
                method: 'POST',
                headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
                body: qs.stringify(this.props.params)
            }).then((response) => response.json())
            .then((responseData) => {
                if (responseData.response == 1) {
                    this.finishTask()
                    this.props.updateMoney(this.props.priceReview)
                } else {
                    this.props.showErrorResponse(responseData.status, "Понятно")
                }
                this.loadCaptcha()
                this.updateLastResponse("perfomReview", responseData)
                console.log("startPerfom" + JSON.stringify(responseData))
            })
    }
    onButtonClick = () => {
        this.startTaskTime = new Date().getTime() / 1000
        var appUrl = this.installUrl + this.props.task.bundle
        // if (this.props.isRedirect){
        //     appUrl = "https://vk.com/away.php?utf=1&to=" + appUrl
        // }
        //console.log("appUrl 3 = " + appUrl)
        this.openUrl(appUrl)
    }
    render(){
      
            return (
                <div className={this.state.isFinished ? 'finished_task_bg' : this.props.isEnabled || this.state.isEnabled ? 'block' : 'not_ready'}>
                    <div className="flex padding_16">
                        <div className="img_icon_container">
                            <img width="40" height="40" className="icon_task_type" src={this.state.isFinished ? check : taskLogo} alt=""/>
                        </div>
                        
                        <div className="block padding_left_16">
                            {this.props.task.review_words != "Поставьте оценку 5 звезд, отзыв НЕ пишите." ? <div>
                                <p className="task_name">Напишите положительный отзыв и поставьте оценку 5 звёзд</p>
                                <p className={this.props.task.review_words ? 'task_name' : 'not_display'}>В отзыве используйте ключевые слова:
                                    <strong>{this.props.task.review_words}</strong></p>
                                <p className={this.props.task.review_wish ? 'task_name' : 'not_display'}>Важное пожелание:
                                    <strong>{this.props.task.review_wish}</strong></p>
                            </div> : <p className="task_name">{this.props.task.review_words}</p>}
                            
                            <div className="flex justify_content">
                                <button onClick={this.onButtonClick} disabled={(this.state.isFinished || !this.state.isReadyStart) && !(this.props.isEnabled || this.state.isEnabled)}
                                className={this.state.isFinished ? 'finished_btn' : 'task_btn'}>{this.state.btn}</button>
                                <p className="text_task_money">+{this.props.priceReview}₽</p>
                            </div>

                        </div>

                    </div>
                    <div className="line"></div>
             </div>

            ); 
        
        
    }
}
export default ThirdTask;