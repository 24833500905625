/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';

import { openUrl, copyId } from '../pages_android/custom_views/insta_custom_views/utils_android/AndroidMethods';
class MaterialMenuAndroid extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            count_money : 0.1,
            currentView : 'tasks',
            isOpened : this.props.isMenuOpened
        }
        this.changeView = this.changeView.bind(this);
    }
    componentDidUpdate(prevProps){
        if (this.props != prevProps){
            this.setState({isOpened : this.props.isMenuOpened})
        }
    }
    componentDidMount(){
        this.setState({count_money : this.props.count_money_r})
    }
    changeView(viewId){
        // this.setState({currentView : viewId})
        this.props.onMenuClicked(viewId)  
        this.props.toogleMenu(false)
    }

    openAnswers = () => {
        openUrl("https://vk.com/@muchmoneyapp-questions")
    }
    openRules = () => {
        openUrl("https://vk.com/@muchmoneyapp-pravila")
    }
    copyMyId = () => {
        copyId(this.props.google_user_id, "ID скопирован")
    }
    render() {
        var anchor = 'left'
        const activeView = this.props.activeView
        return (
            
            <div>
                <SwipeableDrawer
                        anchor={anchor}
                        onClose={() => {this.props.toogleMenu(false)}}
                        onOpen={() => {}}
                        open={this.state.isOpened}>
                            
                            <div>
                                <div style={this.props.isUpmob ? {background : "#3c525f"} : {background : "#01a862"}} className="block header_user">
                                    <div className="flex">
                                        <div className="avatar_container">
                                            <img className="avatar_icon" src={this.props.photo} alt=""/>
                                        </div>

                                        <div className="block justify_content user_container">
                                            <p className="user_name email_aside">{this.props.name}</p>
                                            <p className="email email_aside">{this.props.email}</p>
                                            <p className={this.props.isUpmob ? 'user_name' : 'not_display'}>{parseFloat(this.props.count_money_r).toFixed(2)}₽</p>
                                        </div>

                                    </div>
                                    <div onClick={this.copyMyId} className="flex justify_content copy_container">
                                        <p className="email">id: {this.props.google_user_id}</p>
                                        <img className="copy_icon" src="/icons_android/ic_copy_android.svg" alt=""/>
                                    </div>
                                </div>
                                
                                <ul className="nav">
                            
                                    <li><a className={activeView == "tasks" ? "nav__item-selected" : "nav__item"} onClick={() => {this.changeView("tasks")}}>Новые задания</a></li>
                                    <li><a className={activeView == "active" ? "nav__item-selected" : "nav__item"} onClick={() => {this.changeView("active")}}>Активные задания</a></li>
                                    <li><a className={activeView == "finished" ? "nav__item-selected" : "nav__item"} onClick={() => {this.changeView("finished")}}>Завершенные
                                            задания</a></li>
                                <div className={this.props.isUpmob ? 'block' : 'not_display'}>
                                        <li><a className={activeView == "referals" ? "nav__item-selected" : "nav__item"} onClick={() => {this.changeView("referals")}}>Пригласить
                                                друзей</a></li>
                                        <li><a className={activeView == "payouts" ? "nav__item-selected" : "nav__item"} onClick={() => {this.changeView("payouts")}}>Заказать выплату</a>
                                        </li>
                                        <li><a className="nav__item" onClick={this.openAnswers} href="#">Ответы на часто задаваемые
                                                вопросы</a></li>
                                        <li><a className="nav__item" onClick={this.openRules} href="#">Правила</a></li>
                                        
                                    </div>
                                    <li><a className={activeView == "support" ? "nav__item-selected" : "nav__item"} onClick={() => {this.changeView("support")}}>Служба поддержки</a></li>
                                    <li><a style={{background : 'var(--btn_bg)'}} className={"nav__item"} onClick={() => openUrl("https://upsocial.ru/hamster")}>Рефералы в Hamster Kombat 🔥</a></li>
                                        
                                    
                                </ul>
                            </div>
                    </SwipeableDrawer>
            </div>
            );
        }
}
export default MaterialMenuAndroid;
  
