import React from 'react';
import ActiveTaskItem from './list_items/ActiveTaskItem';
import qs from 'querystring'
import {getRequestParams, getRemovedOrders} from './custom_views/insta_custom_views/utils_android/CookieHelper'
import Loader from './custom_views/insta_custom_views/utils_android/LoaderAndroid';
import ufoIcon from './custom_views/insta_custom_views/insta_icons_tasks/ufo_android.png';
import CustomTaskItem from './list_items/CustomTaskItem';

class ActiveTasks extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            done : false,
            activeYoutubeTasks : []
        }
    }
    componentDidMount(){
        this.getMyActiveYoutube()
    }
    getActiveTasks = () => {
        var finalTasks = []
        var removedOrders = getRemovedOrders()
        if (this.props.info != undefined && this.props.info.progress_orders != undefined){
            var tasks = this.props.info.progress_orders
            for (let index = 0; index < tasks.length; index++) {
                const element = tasks[index];
                if (!removedOrders.includes(element.bundle)){
                    element.category = "Активно"
                    if (element.progressArraySDK != undefined && element.progressArraySDK.length > 0 && element.progressArraySDK.includes(0)){
                        finalTasks.push(element)
                    } else if (element.progressArray.includes(0)) {
                        finalTasks.push(element)
                    }
                }
            }
            return finalTasks.reverse()
        } else {
            return []
        }
    }
    getMyActiveYoutube = () => {
        var params = getRequestParams()
        fetch("https://app-coins.ru/api/getMyActiveYoutube", {
            method: 'POST',
            headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
            body: qs.stringify(params)
        }).then((response) => response.json())
        .then((responseData) => {
            if (responseData.response == 1 && responseData.proof_orders_youtube && responseData.proof_orders_youtube.length > 0){
                var activeTasks = []
                responseData.proof_orders_youtube.forEach(task => {
                    if ((task.state != 1 && task.state != -1) && task.order != null){
                        activeTasks.push(task)
                    }
                });
                this.setState({activeYoutubeTasks : activeTasks})
            }
            this.setState({done : true})
        })
    }
    render() {
        const tasks = this.getActiveTasks()
        if (!this.state.done){
            return(<Loader/>) 
        } else if (tasks.length > 0 || this.state.activeYoutubeTasks.length > 0){
            return (
                <div>
                    <div className="flex justify_content pad_left_0">
                        <p className="task_name active_tasks_text">Активные задания</p>
                    </div>
                    {this.state.activeYoutubeTasks.map((item, index) => (
                            <CustomTaskItem onTaskClicked={this.props.onTaskClicked} correction={item.correction} isActive={true} proof_order_id={item._id} orderState={item.state} proof_text={item.proof_text} task={item.order} index={index} key={index}
                            description_state={item.state == 1 ? "<p style='color : #2DB789'>Одобрено</p>" : (item.state == 2 ? "<p style='color : #E17373'>Нужно доработать</p>" : (item.state == -1 ? "<p style='color : #E17373'>Отклонено</p>" : (item.state == 0 ? "<p>На проверке</p>" :  (item.state == 3 ? "<p>Выполняется</p>" :  undefined))))}/>
                    ))}
                    {tasks.map((item, index) => (
                            <ActiveTaskItem backView="active" onTaskClicked={this.props.onTaskClicked} isActive={true} task={item} key={index}/>
                    ))}
                </div>
            );
        } else {
            return (
                <div className="empty_tasks_container">
                    <div className="display_block">
                        <img className="ufo_icon" src={ufoIcon} alt=""/>
                        <p className="empty_tasks_text_new">На данный момент<br/>активных заданий нет</p>
                    </div>
                </div>
            )
        } 
    }
}
export default ActiveTasks;
