import React from 'react';
import qs from 'querystring'
import {getRequestParams, getCookie, setCookie} from './custom_views/insta_custom_views/utils_android/CookieHelper'
import ExtraComment from './custom_views/insta_custom_views/ExtraComment';
import BaseEarnTask from './custom_views/insta_custom_views/BaseEarnTask';
import ExtraFollow from './custom_views/insta_custom_views/ExtraFollow';
import ExtraView from './custom_views/insta_custom_views/ExtraView';
import PhotoTask from './custom_views/insta_custom_views/PhotoTask';
import LoaderEarn from './custom_views/insta_custom_views/utils_android/LoaderEarnAndroid';
import EmptyTask from './custom_views/insta_custom_views/EmptyTaskAndroid';
import { ReCaptcha } from 'react-recaptcha-v3'
import FineDialog from './custom_views/insta_custom_views/FineDialog';
import TikTokTask from './custom_views/insta_custom_views/TikTokTask';
import { getHttpParams } from './custom_views/insta_custom_views/utils_android/UrlHelperAndroid';
import AuthTikTok from './AuthTiktok';
class EarnTikTok extends BaseEarnTask {
    constructor(props){
        super(props)
        this.state = {
            currentDescription : "",
            isLoading : true,
            currentExtraOrder : {},
            currentPhotoTask : {},
            isCurrentExtra : true,
            currentType : -1,
            extraOrdersCount : 0,
            isShowEmpty : false,
            fineCoinsCount : 0,
            isAuthFinished : false
        }
        this.viewsTitle = 'Перейди в TikTok и посмотри видео минимум 7 секунд. При желании поставь лайк и напиши комментарий.'
        this.followersTitle = 'Перейди в TikTok и подпишись на аккаунт.'
        this.followersTitle2 = 'НЕ отписывайся минимум неделю.'
        
    }
    componentDidMount(){
        this.getPhotoTask()
    }
   
    showLoader = () => {
        this.setState({isLoading : true})
    }
    hideLoader = () => {
        this.setState({isLoading : false})
    }
    loadNextOrder = (isLoadPhoto) => {
        this.getPhotoTask()
    }
    getExtraOrder = () => {
              
    }
    getPhotoTask = () => {
        this.showLoader();
        var params = getRequestParams();
        params.user_id = getCookie("user_id_tiktok")
        params.token = getCookie("user_id_tiktok")
        console.log("getPhotoTask params = " + JSON.stringify(params))
        fetch("https://app-coins.ru/api/getPhotoTaskTikTok", getHttpParams(params)).then((response) => response.json())
                .then((responseData) => {
                    console.log("getPhotoTaskTikTok = " + JSON.stringify(responseData));
                    if (responseData.photo) {
                        this.setState({currentPhotoTask : responseData, isCurrentExtra: false, currentDescription : this.VIDEO_TASK_DESCRIPTION, currentType : 1})
                    } else {
                        this.showNoTasksView();
                    }
                    this.props.setMoney(responseData.count_money_r)
                    this.setState({isLoading : false})
                    
                })         
    }

    showNoTasksView = () => {
        console.log("showNoTasksView")
        this.setState({isShowEmpty : true, isLoading : false, currentPhotoTask : {}})
    }   
    onOrderSkipped = () => {
        var skipOrders = this.state.extraOrdersCount + 1
        this.setState({extraOrdersCount : skipOrders})
        console.log("skipOrders count = " + skipOrders)
        return skipOrders
    }
    authFinished = () => {
        this.setState({isAuthFinished : true, isLoading : false})
    }
    changeAcc = () => {
        setCookie("uniqueIdTikTok", "")
        setCookie("user_id_tiktok", "")
        this.setState({isAuthFinished : false, isLoading : false})
    }
    render() {
        const savedTikTokUsername = getCookie("uniqueIdTikTok")
        const savedTikTokUser_id = getCookie("user_id_tiktok")
        const is_authed_tiktok = getCookie("is_authed_tiktok5")
        const isShowAuth = !this.state.isAuthFinished && (savedTikTokUsername == undefined || savedTikTokUsername == null || savedTikTokUsername == "" || savedTikTokUser_id == undefined || savedTikTokUser_id == null || savedTikTokUser_id == "" || is_authed_tiktok == undefined || is_authed_tiktok == null)
        //const isShowAuth = true
        if (isShowAuth){
            return (
                <AuthTikTok authFinished={this.authFinished}/>
            )
        } else {
            return (
                <section className="earn-money_section">
                    {/* {this.state.currentPhotoTask.photo != undefined ? 
                        this.state.currentPhotoTask.photo.type == 0 ? 
                            <p className="text_earn">{this.viewsTitle}</p>
                        :   <p className="text_earn">{this.followersTitle}<br/><b>{this.followersTitle2}</b></p>
                        : <p></p>
                    } */}
                    {this.state.currentPhotoTask.photo && this.state.currentType == 1 && <TikTokTask order={this.state.currentPhotoTask.photo} showLoader={this.showLoader} loadNextOrder={this.loadNextOrder} isCurrentExtra={this.state.isCurrentExtra} currentPhotoTask={this.state.currentPhotoTask} hideLoader={this.hideLoader} onOrderSkipped={this.onOrderSkipped} extraOrdersCount={this.state.extraOrdersCount}/>}
                    {this.state.isShowEmpty && <EmptyTask isTiktok={true} showLoader={this.showLoader} loadNextOrder={this.loadNextOrder}/>}
                    {/* {this.state.isLoading && <LoaderEarn/>} */}
                
                    {/* <div onClick={this.changeAcc} className="change_acc_insta">
                        <div className="center_change_acc">
                            <p className="change_acc_text">Сменить аккаунт</p>
                        </div>
                    </div> */}

                </section>
            );
        }
    }
}
export default EarnTikTok;