import React from 'react';
import { copyId, openUrl } from './custom_views/insta_custom_views/utils_android/AndroidMethods';
import copyIcon from './icons_android/ic_copy_android.svg'
class Support extends React.Component {
    constructor(props){
        super(props)
        
    }
    openLink = () => {
        copyId(this.props.google_user_id, "ID скопирован")
        if (this.props.isUpmob){
            openUrl("https://vk.com/muchmoneyapp")
        } else {
            openUrl("https://vk.com/smooozi")
        }
        
    }
    copyClicked = () => {
        copyId(this.props.google_user_id, "ID скопирован")
    }
    render() {
        return (
            <div class="block padding_16">
                <p class="task_name">Если у вас возникли проблемы или есть пожелания, напишите. При обращении
                    напишите свой ID и суть проблемы.</p>
                <div onClick={this.copyClicked} class="flex justify_content copy_id_support">
                    <p class="task_name">Ваш ID  <strong>{this.props.google_user_id}</strong></p>
                    <img class="copy_support_id_icon" src={copyIcon} alt=""/>
                </div>
                <div onClick={this.openLink}  class="open_support_btn">Написать в службу поддержки</div>

            </div>
        );
    }
}
export default Support;