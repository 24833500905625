import React from 'react';
import empty_image_insta from './insta_icons_tasks/empty_image_insta.svg'
import message_square_insta_android from './insta_icons_tasks/message_square_insta_android.svg'
import chevron_next_insta_android from './insta_icons_tasks/chevron_next_insta_android.svg'
import BaseEarnTask from './BaseEarnTask';
import qs from 'querystring'
import {getRequestParams} from './utils_android/CookieHelper'
import { ReCaptcha } from 'react-recaptcha-v3'
class ExtraComment extends BaseEarnTask {
    constructor(props){
        super(props)
        this.state = {
            currentDescription : "",
            isUpdateCaptcha : false,
            isImgError : false
        }
        this.startTaskTime = -1
        window.extraComment = this
        this.toogleImgError = this.toogleImgError.bind(this);
    }
    onResume(){
        if (this.startTaskTime != -1){
            this.verifyCallback()
        }
    }
    toogleImgError(){
        this.setState({isImgError : true})
    }
    verifyCallback = (recaptchaToken) => {
        this.setState({isUpdateCaptcha : false})
        this.props.showLoader()
        var currentTime = new Date().getTime() / 1000
        if (currentTime - 1 >= this.startTaskTime){
            this.checkFollowTask(recaptchaToken)
        }
    }
    checkFollowTask = (recaptchaToken) => {
        setTimeout(() => this.instaComment(recaptchaToken), 500);
    }
    doTask = () => {
        this.startTaskTime = new Date().getTime() / 1000
        this.openUrl(this.props.currentExtraOrder.insta_url)
    }
    instaComment = (recaptchaToken) => {
        var params = getRequestParams();
        params._id = this.props.currentExtraOrder.order._id;
        params.photo_id = this.props.currentExtraOrder.order.media_id;
        if (recaptchaToken != undefined){
            params.timestamp = recaptchaToken
        }
        params.text = ""
        fetch("https://app-coins.ru/api/instaComment", {
            method: 'POST',
            headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
            body: qs.stringify(params)
        }).then((response) => response.json())
        .then((responseData) => {
            if (responseData.response == 1 || responseData.response == 2) {
                this.props.loadNextOrder()
            } else {
                this.errorTask()
            }
            this.startTaskTime = -1
            console.log("instaComment" + JSON.stringify(responseData))
        })
    }
    skip = () => {
        this.skipOrder(false, this.props.currentExtraOrder.order._id)
    }
  
    render() {
        return (
                <div className="rate-content__item">
                    <div className="photo_task_container">
                        <img width="64" height="64" className="placeholder_task icon_64"
                            src={empty_image_insta} alt=""/>
                        <img style={this.state.isImgError ? {opacity : '0'} : {}} onError={this.toogleImgError} className="image_task_holder" src={this.props.currentExtraOrder.userInfo ? this.getSource(this.props.currentExtraOrder.userInfo.items[0]) : this.props.currentExtraOrder.order.photo_url}
                            alt="" width="360" height="360"/>
                        <img onClick={this.skip} src={chevron_next_insta_android} className="arrows__item arrows__item_next arrow_item_follower"></img>
                        {/* <p onClick={this.appealClicked} className="appeal_text appeal_text_bg">Пожаловаться</p> */}
                    </div>


                    <div className="rate-content__actions">
                        <div onClick={this.doTask} className="btn-follow top_10">
                            <img className="btn_task_icon" src={message_square_insta_android} alt=""/>
                            Написать коммент
                        </div>
                        <div className="coins_rub_lashka rub_plashka">
                            <span
                                className="subscribe_follow_reward">+{this.props.currentExtraOrder.earn_money}₽</span>
                        </div>
                    </div>
                    {this.state.isUpdateCaptcha && <ReCaptcha
                        sitekey="6LdFRuEUAAAAAGBfnPHHxCvPSn8bl5ISdZSLiu7_"
                        action='insta'
                        verifyCallback={this.verifyCallback}/>
                    } 
                </div>
        );
    }
}
export default ExtraComment;
