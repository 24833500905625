import React from 'react';
import close from '../../../pages_android/icons_android/x_android.svg'
import LoaderSearch from './LoaderSearch';

class ErrorTikTokDialog extends React.Component {
    constructor(props){
        super(props)

    }
    
    render(){
      
            return (
                <div className="error_bg">
                    <div className="error_banner_container">
                            <img onClick={() => this.props.closeBannerClicked()} className="close_icon_error" src={close}
                                alt=""/>
                            <p className="text_error_b">{this.props.errorTitle}</p>
                            <div className="flex relative">
                                <p style={this.props.isLoading ? {opacity : 0, pointerEvents : 'none'} : {}} onClick={() => this.props.successClicked()} className="error_btn">{this.props.errorBtn}</p>
                                {this.props.isLoading && <LoaderSearch isTikTok={true} style={{padding : '16px'}}/>}

                            </div>
                    </div>  
                </div>
            ); 
        
        
    }
}
export default ErrorTikTokDialog;