import React from 'react';
import '../../../css_android/loader.css'
class LoaderSearch extends React.Component {
    constructor(props){
        super(props)

    }
    
    render(){
      
            return (
                <div>
                    {this.props.isTikTok == undefined && <p className="position_search_text">Поиск позиции:</p>}
                    <div className="lds-ring-search">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
                
            ); 
        
        
    }
}
export default LoaderSearch;