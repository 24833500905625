import React from 'react';
import qs from 'querystring'
import { getRequestParams, getInstaLoginCookie, getInstaUseridCookie } from '../custom_views/insta_custom_views/utils_android/CookieHelper';
import { openUrl } from '../custom_views/insta_custom_views/utils_android/AndroidMethods';
import { getHttpParams, getRequestUrl } from '../custom_views/insta_custom_views/utils_android/UrlHelperAndroid';
class CustomTaskItem extends React.Component {
    constructor(props) {
        super(props);
    }
    openTask = function(){
        if (this.props.task._id == "-9"){
            openUrl(this.props.task.url)
            this.getMoneyFromVideo2()
        } else if (this.props.task._id == "0"){
            //window.location.href = '/auth_insta?back=tasks&testReactJS=1'
            const hasInstaLogin = getInstaLoginCookie() != null && getInstaLoginCookie() != undefined && getInstaLoginCookie() != ""
            const hasInstaUserId = getInstaUseridCookie() != null && getInstaUseridCookie() != undefined && getInstaUseridCookie() != ""
            if (hasInstaLogin && hasInstaUserId){
                this.props.openViewBack("earn_insta", "tasks")
            } else {
                this.props.openViewBack("auth_insta", "tasks")
            }
            
        } else if (this.props.task._id == "1"){
           // window.location.href = '/tiktok_task?back=tasks&testReactJS=1'
           this.props.openViewBack("tiktok_task", "tasks")
        } else if (this.props.task._id == "2"){
            this.props.onTaskClicked(this.props.task, "login_task", "tasks")
        } else {
            if (this.props.task.control_text){
                this.startPerfomYoutube()
            } else {
                var task = this.props.task
                task.task_data = {'proof_order_id' : '-1', 'orderState' : '3', 'orderAnswer' : ''}
                this.props.onTaskClicked(task, 'custom_task', 'tasks')
               // window.location.href = '/custom_task?back=tasks&order_id=' + this.props.task._id + '&proof_order_id=-1' + "&orderState=3&orderAnswer="
            }
            
        }
        
    }
    getMoneyFromVideo2 = () => {
        const params = getRequestParams()
        fetch(getRequestUrl() + "getMoneyFromVideo2", getHttpParams(params)).then((response) => response.json())
            .then((responseData) => {
                if (responseData.response > 0) {
                    this.props.updateMoney(0.03)
                    if (this.props.count_money_r >= 0.03){
                        this.props.updateOrders()
                    }
                   
                } 
            })
    }
    startPerfomYoutube = () => {
        console.log("startPerfomYoutube")
        if(this.props.orderState != undefined){
            var task = this.props.task
            task.task_data = {'proof_order_id' : (this.props.proof_order_id ? this.props.proof_order_id : "123" ), 'orderState' : (this.props.orderState != undefined ? this.props.orderState : 3), 'orderAnswer' : (this.props.proof_text ? this.props.proof_text : ""), 'correction' : this.props.correction}
            this.props.onTaskClicked(task, 'custom_task', (this.props.isActive ? 'active' : 'finished'))
            //window.location.href = '/custom_task?back=' + (this.props.isActive ? 'active' : 'finished') + '&order_id=' + this.props.task._id + '&proof_order_id=' + (this.props.proof_order_id ? this.props.proof_order_id : "123" ) + "&orderState=" + (this.props.orderState != undefined ? this.props.orderState : 3) + "&orderAnswer=" + (this.props.proof_text ? this.props.proof_text : "") + "&correction=" + this.props.correction
            return
        }
        // var params = getRequestParams();
        // params.order_id = this.props.task._id;
        // fetch("https://app-coins.ru/api/startPerfomYoutube", {
        //     method: 'POST',
        //     headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
        //     body: qs.stringify(params)
        // }).then((response) => response.json())
        // .then((responseData) => {
        //     if (responseData.response == 1 || responseData.response == 2) {
        //         var task = this.props.task
        //         task.task_data = {'proof_order_id' : responseData.proof_order_id, 'orderState' : (this.props.orderState != undefined ? this.props.orderState : 3), 'orderAnswer' : (this.props.proof_text ? this.props.proof_text : "")}
        //         this.props.onTaskClicked(task, 'custom_task', 'tasks')
        //     } else {
        //         this.props.orderError()
        //     }
        //     console.log("startPerfomYoutube" + JSON.stringify(responseData))
        // })
        var task = this.props.task
        task.task_data = {'orderState' : (this.props.orderState != undefined ? this.props.orderState : 3), 'orderAnswer' : (this.props.proof_text ? this.props.proof_text : "")}
        this.props.onTaskClicked(task, 'custom_task', 'tasks')
    }
    render() {
        return (
            <div className="description_full" onClick={() => this.openTask()}>
                <div className="flex justify_content bot_12 description_full">
                    <div className="flex description_full">
                        <div className="icon_container">
                            <img className="tasks_icon custom_icons_task_shadow" src={this.props.task.icon} alt=""/>
                        </div>
                        
                        <div className="block justify_content text_container">
                            <p className="task_name task_name_overflow">{this.props.task.name}</p>
                            <p className="task_description description_full custom_desc" dangerouslySetInnerHTML={{__html: this.props.task.description_state ? this.props.task.description_state : "Выполните задание и получите деньги"}}></p>
                        </div>
                        {this.props.orderState == undefined && <p className={(this.props.task._id == "0" || this.props.task._id == "1") ? "not_display" : "money_text"}>+{this.props.task.count_earning_employer_r}₽</p>} 
                        {this.props.orderState == 2 && <div className="notification_text"><p className="notif_text_m">1</p></div>} 
                    </div>
                </div>
                <div className="line_full"></div>
            </div>
        );
    }
    
}
export default CustomTaskItem;