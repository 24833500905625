import React from 'react';
import { openUrl, showAndroidToast } from './custom_views/insta_custom_views/utils_android/AndroidMethods';
import { getRequestParams } from './custom_views/insta_custom_views/utils_android/CookieHelper';
import Loader from './custom_views/insta_custom_views/utils_android/LoaderAndroid';
import screen_need from '../pages_android/icons_android/screen_need.svg'
import load_screen from '../pages_android/icons_android/load_screen.svg'
import help_screen from '../pages_android/icons_android/help_screen.svg'
import { ReCaptcha } from 'react-recaptcha-v3'
import ContentEditable from 'react-contenteditable'
import ScreenUploadInstruction from './custom_views/insta_custom_views/ScreenUploadInstruction';
import { proccessRandomVariants } from './custom_views/insta_custom_views/utils_android/TextConverter';
import { getHttpParams } from './custom_views/insta_custom_views/utils_android/UrlHelperAndroid';
class CustomTask extends React.Component {
    constructor(props){
        super(props)
        var proof_order_id = this.props.task.task_data.proof_order_id
        var orderState = this.props.task.task_data.orderState 
        var orderAnswer = this.props.task.task_data.orderAnswer
        var correction = this.props.task.task_data.correction
        this.state = {
            isCompletedTask : false,
            order : this.props.task,
            isLoading : false,
            isUpdateCaptcha : false,
            isShowTimeError : false,
            isShowTimer : false,
            proof_order_id : proof_order_id,
            answer : orderAnswer ? orderAnswer : "",
            isShowReview : false,
            timerTime : 59,
            orderState : parseInt(orderState),
            correction : correction ? correction : "",
            isShowScreenUploadInstruction : false,
            urlsIndex : -1,
            description : ""
        }
        this.answerChanged = this.answerChanged.bind(this)
        this.startTaskTime = -1
        this.timestamp = ""
        window.customTask = this
    }
    componentDidMount(){
        this.getDescription(this.props.task)
        if (document.getElementsByClassName('PrivateSwipeArea-root-1').length > 0){
            document.getElementsByClassName('PrivateSwipeArea-root-1')[0].style.width = 'auto'
        } else if (document.getElementsByClassName('jss1').length > 0){
            document.getElementsByClassName('jss1')[0].style.width = 'auto'
        }
        console.log("custom task = ", this.state.order)
    }
    verifyCallback = (recaptchaToken) => {
        if (recaptchaToken){
            this.timestamp = recaptchaToken
        }
        
        this.setState({isUpdateCaptcha : false})
        
        if (this.state.order.control_text && this.state.order.seconds_count && this.state.order.seconds_count > 0){
            if (this.startTaskTime != -1){
                var currentTime = new Date().getTime() / 1000
                if (currentTime - this.state.order.seconds_count >= this.startTaskTime){
                    this.setState({isShowTimeError : false})
                    if (!this.state.isShowReview){
                        this.setState({isShowReview : true})
                        this.startTimer()
                    } 
                    
                } else {
                    this.setState({isShowTimeError : true})
                    
                }
            }
        } else if (this.state.order.control_text){
            if (!this.state.isShowReview){
                this.setState({isShowReview : true})
                this.startTimer()
            } 
        } else {
            if (this.startTaskTime != -1){
                var currentTime = new Date().getTime() / 1000
                if (currentTime - this.state.order.seconds_count >= this.startTaskTime){
                    this.setState({isShowTimeError : false})
                    this.finishTask(recaptchaToken)
                } else {
                    this.setState({isShowTimeError : true})
                    //showAndroidToast("Вы вернулись раньше времени.")
                    //showAndroidToast("Вернитесь и посмотрите видео минимум 7 секунд.")
                }
            }
        }
        
    }
    uploadScreen = () => {
         openUrl("https://ru.imgbb.com")
       // window.href = "ru.imgbb.com"
        //window.open("ru.imgbb.com");
    }
    onResume(){
       // this.setState({isUpdateCaptcha : true})
        this.verifyCallback()
    }
    startTimer = () => {
        setInterval(this.updateTimer, 60000);
    }
    updateTimer = () => {
        if (this.state.timerTime >= 0){
            this.setState({timerTime : this.state.timerTime - 1})
        }
    }
    // getOrder = (order_id) => {
    //     var params = getRequestParams();
    //     params._id = order_id
    //     fetch("https://app-coins.ru/api/getOrderYoutubeLightByID", {
    //         method: 'POST',
    //         headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
    //         body: qs.stringify(params)
    //     }).then((response) => response.json())
    //     .then((responseData) => {
    //         if (responseData.response == "1" && responseData.order){
    //             this.setState({order : responseData.order, isLoading : false})

    //         } else {
    //             showAndroidToast("Ошибка в заказе")
    //         }
    //     })
    // }
    finishTask = (recaptchaToken) => {
        if (this.state.order.control_text && (this.state.answer == "" || this.state.answer == " " || this.state.answer == undefined)){
            showAndroidToast("Поле с отчетом не может быть пустым. Если требуется загрузить скриншот, используйте файлообменник и вставьте в отчет ссылку на скриншот.")
            return
        }
        var params = getRequestParams();
        if (recaptchaToken){
            params.timestamp = recaptchaToken
        }
        params.order_id = this.state.order._id;
        params.proof_order_id = this.state.proof_order_id;
        if (this.state.answer != "") {
            params.proof_text = this.state.answer
        }
        fetch("https://app-coins.ru/api/startPerfomYoutube", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            if (responseData.response == 1 || responseData.response == 2) {
                //showAndroidToast("Задание успешно выполнено")
                this.setState({isCompletedTask : true})
                if(this.state.order.control_text == undefined || this.state.order.control_text == ""){
                    this.setState({orderState : 1, isShowTimer : false})
                    this.props.updateMoney(this.state.order.count_earning_employer_r)
                }
                // if (this.state.order.control_text && this.state.order.seconds_count && this.state.order.seconds_count > 0){
                //     this.setState({orderState : 0, isShowTimer : false})
                // }
            }
            this.startTaskTime = -1
        })
    }
    getRealUrl = () => {
        const url = this.state.order.url
        if (url.includes(";") && this.state.urlsIndex != -1){
            return url.split(";")[this.state.urlsIndex]
        }
        return url
    }
    doTask = () => {
        if (this.state.proof_order_id != undefined){
            this.startTaskTime = new Date().getTime() / 1000
            if(this.state.order.control_text == undefined && !this.state.isShowTimer){
                this.setState({isShowTimer : true})
                this.startTimer()
            }
            
            openUrl(this.getRealUrl())
            return
        }
        var params = getRequestParams();
        params.order_id = this.props.task._id;
        fetch("https://app-coins.ru/api/startPerfomYoutube", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            if (responseData.response == 1 || responseData.response == 2) {
                this.setState({proof_order_id : responseData.proof_order_id})
                this.startTaskTime = new Date().getTime() / 1000
                if(this.state.order.control_text == undefined && !this.state.isShowTimer){
                    this.setState({isShowTimer : true})
                    this.startTimer()
                }
                openUrl(this.getRealUrl())
            } else {
                // this.props.orderError()
                showAndroidToast("Ошибка. Данный заказ уже не доступен.")
            }
            console.log("startPerfomYoutube" + JSON.stringify(responseData))
        })
    }
    sendReview = () => {
        this.finishTask(this.timestamp)
        this.setState({orderState : 0})
    }
   
    answerChanged(event){
        if (event){
            var value = event.target.value
            this.setState({answer : value})
        }
    }
    editAnswer = () => {
        this.setState({orderState : 3, answer : ""})
    }
    toggleScreenUpload = () => {
        this.setState({isShowScreenUploadInstruction : !this.state.isShowScreenUploadInstruction})
    }
    getDescription = (order) => {
        var descr = order.description
    //     descr = "1) Перейти по ссылке [раскрутка приложений в google play; продвижение в мобильных приложений] и найти статью с заговолком [Раскрутка приложений в Google Play бесплатно; Продвижение приложений в Google Play бесплатно]</br>" +
    //   "2) Нажать на нее. И найти на странице фразу [Видео c YouTube как бесплатная...;Видео c Вимео как бесплатная....] В этой фразе не хватает одного слова. Его и нужно узнать для отчета."
        if (descr.includes("[") && descr.includes("]")){
            const variants = proccessRandomVariants(descr)
            const result = variants[0]
            if (result != "-1" && result != "-2"){
                this.setState({urlsIndex : variants[2], description : result})
                return
            }
        }
        this.setState({description : descr})
    }

    render() {
        if (this.state.isLoading){
            return(<Loader/>) 
        } else {
            return (
                <div className="empty_tasks_container">
                    <div className="padding_16">
                        <div className="flex">
                            <div className="custom_icon_container ">
                                <img className="custom_icon" src={this.state.order.icon} alt=""/>
                            </div>
                           
                            <div>
                                <p className="custom_task_name">{this.state.order.name}</p>
                                <p className="money_text custom_money_text top_price_0">+{this.state.order.count_earning_employer_r}₽</p>
                            </div>
                            
                        </div>
                        <p className="custom_task_description" dangerouslySetInnerHTML={{__html: this.state.description}}/>
                        {/* <div onClick={() => this.onResume("asdasd")}>verifyCallback</div> */}
                    </div>
                   
                    <div className="custom_task_review">
                        <p className="custom_task_review_title">{this.state.order.control_text ? "Что нужно указать в отчёте о выполненном задании:" : "Таймер задания – " + this.state.order.seconds_count + " сек."}</p>
                        <p dangerouslySetInnerHTML={{__html: this.state.order.control_text ? this.state.order.control_text : "Если вы вернётесь раньше, то задание не будет засчитано и вознаграждение не будет начислено."}}/>
                        {(this.state.order.is_need_screenshot && !this.state.isShowReview) && <div className="flex">
                            <img className="is_need_screen_img" src={screen_need}/>
                            <p className="is_need_screen_text">Для отчёта нужен скриншот</p>
                        </div>}
                        

                        {this.state.isShowReview && this.state.orderState == 3 && 
                            <div>
                                {this.state.order.is_need_screenshot &&
                                    <div>
                                        <div className="flex justify_content">
                                        <div onClick={this.uploadScreen} className="load_screen_btn">
                                            <img src={load_screen}/>
                                            <p className="load_screen_text_btn">Загрузить скриншот</p>
                                        </div>
                                        <div onClick={this.toggleScreenUpload} className="help_screen_icon">
                                            <img src={help_screen}/>
                                        </div>
                                    </div>
                                    <p className="load_screen_descr">А потом вставьте ссылку на скриншот в поле ниже. При необходимости напишите свои комментарии.</p>
                                </div>}

                                <ContentEditable contentEditable="true" placeholder="Введите текст отчёта" onChange={this.answerChanged} className="review_text" html={this.state.answer}/>
                                <div style={this.state.answer == "" ? {opacity : 0.2, pointerEvents : 'none'} : {}} onClick={this.sendReview} className="send_btn">Отправить</div>
                            </div>
                        }
                        {this.state.orderState != 3 && 
                            <div>
                               {this.state.orderState == 0 && <p className="in_progress_task progress_custom_task">Задание на проверке...</p>}
                               {this.state.orderState == 1 && <div className={this.state.order.control_text ? "progress_custom_task" : "custom_seconds_task"}>
                                   <p className="custom_task_completed">Задание засчитано</p>
                                   <p>Спасибо за работу!<br/>Вам начислено вознаграждение</p>
                               </div>}
                               {(this.state.orderState == 2 || this.state.orderState == -1) && <div className="progress_custom_task">
                                   <p className="custom_task_completed rejection">{this.state.orderState == 2 ? "Задание отклонено" : "Задание отклонено финально"}</p>
                                   {this.state.order.control_text && <div>
                                        <p className="rejection_comment">Комментарий заказчика:</p>
                                        <p dangerouslySetInnerHTML={{__html: this.state.correction}}/>
                                   </div>}
                                   
                               </div>}
                               
                               {this.state.order.control_text &&<div className="flex_answer">
                                    <p className="your_answer">Ваш ответ:</p>
                                    {this.state.orderState != 1 && this.state.orderState != -1 && <p onClick={this.editAnswer} className="edit_answer">редактировать</p>}
                               </div>}
                                
                                {this.state.order.control_text && <p className="m_bot_24" dangerouslySetInnerHTML={{__html: this.state.answer}}/>}
                            </div>
                        }
                        {this.state.isShowTimeError && <div className="custom_seconds_task">
                            <p className="custom_task_completed rejection">Задание отклонено</p>
                            <p>Вы слишком рано вернулись.<br/>Попробуйте ещё раз</p>
                        </div>}
                        
                    </div>
                    {this.state.isShowTimer && <p className="timer_text">На выполнение осталось <strong>{this.state.timerTime} мин.</strong></p>}

                    {this.state.orderState == 3 && <div>
                        {!this.state.isShowReview && <div className="padding_16">
                            {!this.state.isShowTimer && <p>На выполнение задания даётся 1 час.<br/>Отсчёт времени начнётся после нажатия на кнопку</p>}
                        </div>}
                        <div style={this.state.isShowReview ? {margin : '16px 16px 24px 16px'} : {}} onClick={this.doTask} className="open_support_btn start_custom_task_btn">{this.state.isShowReview ? "Открыть ссылку" : "Начать выполнение"}</div>
                        {this.state.order.control_text && !this.state.isShowReview && <div className="divider_line"/>}
                        {this.state.isShowReview && <p className="timer_text">На выполнение осталось <strong>{this.state.timerTime} мин.</strong></p>}
                    </div>}
                    {this.state.orderState == 0 && <div style={{margin : '16px'}} onClick={this.doTask} className="open_support_btn start_custom_task_btn">Открыть ссылку</div>}


                    {(this.state.order.control_text && this.state.orderState != 1 && this.state.orderState != -1) &&
                    <p className="custom_task_earning">Вознаграждение будет начислено после того, как заказчик одобрит ваш отчёт.<br/><br/>Или зачтено автоматически через <strong>{this.state.order.hour_autocomplete == 24 ? "1 сутки" : this.state.order.hour_autocomplete == 48 ? "2 суток" : (this.state.order.hour_autocomplete/24) + " суток"}</strong> после отправки отчёта.</p>}

                    
                    {this.state.isUpdateCaptcha && <ReCaptcha
                        sitekey="6LdFRuEUAAAAAGBfnPHHxCvPSn8bl5ISdZSLiu7_"
                        action='homepage'
                        verifyCallback={this.verifyCallback}/>
                    } 
                    {this.state.isShowScreenUploadInstruction && <ScreenUploadInstruction toggleScreenUpload={this.toggleScreenUpload}/>}
                </div>
            );
        }
    }
}
export default CustomTask;