import React from 'react';
import qs from 'querystring'
import { ReCaptcha } from 'react-recaptcha-v3'
import { getRequestParams, getStartedOrders, addStartedOrder } from '../custom_views/insta_custom_views/utils_android/CookieHelper';
import amplitude from 'amplitude-js'
import { getHttpParams } from '../custom_views/insta_custom_views/utils_android/UrlHelperAndroid';
var readyToPerfomIds = []
class TaskItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isUpdateCaptcha : false
        }
    }
   
    openTask = (recaptchaToken) => {
        this.setState({isUpdateCaptcha : false})
        var params = getRequestParams()
        params.order_id = this.props.task._id
        if (recaptchaToken){
         params.timestamp = recaptchaToken
        }
        console.log("readyToPerfom params = ", params) 
        fetch("https://app-coins.ru/api/readyToPerfom", getHttpParams(params)).then((response) => response.json())
            .then((responseData) => {
                if (responseData.response === 1) {
                    //window.location.href = '/task?order_id=' + this.props.task._id + "&back=tasks&testReactJS=1"
                    amplitude.getInstance().logEvent('openTask');
                    readyToPerfomIds.push({id : this.props.task._id, deleteTime : (new Date().getTime() / 1000 + 1200)})
                    this.props.onTaskClicked(this.props.task, 'task', 'tasks')
                    if (!getStartedOrders().includes(this.props.task._id)){
                        addStartedOrder(this.props.task._id)
                    }
                } else {
                    this.props.orderError()
                }
                console.log("readyToPerfom = " + JSON.stringify(responseData)) 
            })

        
    }
    verifyCallback = (recaptchaToken) => {
        this.openTask(recaptchaToken)  
    }
    onTaskClicked = () => {
        var isFound = false
        var array = readyToPerfomIds
        var i = 0
        array.forEach(element => {
            if ((new Date().getTime() / 1000) >= element.deleteTime){
                readyToPerfomIds.splice(i, 1);
            } else if (element.id == this.props.task._id){
                isFound = true
            }
            i++
        });
        if (isFound){
            this.props.onTaskClicked(this.props.task, 'task', 'tasks')
        } else {
            //this.setState({isUpdateCaptcha : true})
            this.openTask()
        }
        console.log("onTaskClicked = " + isFound)
        
    }
    render() {
        return (
            <div onClick={this.onTaskClicked}>
                <div className="flex justify_content bot_12">
                    <div className="flex">
                        <div className="icon_container">
                            <img className="tasks_icon" src={"https://" + this.props.task.icon} alt=""/>
                        </div>
                        
                        <div className="block justify_content text_container">
                            <p className="task_name task_name_overflow">{this.props.task.name}</p>
                            <p className="task_description">{this.props.task.category}</p>
                        </div>
                    </div>
                    <p className="money_text">+{this.props.task.price_one_install_employer}₽</p>
                </div>
                <div className="line_full"></div>
                {this.state.isUpdateCaptcha && <ReCaptcha
                        sitekey="6LdFRuEUAAAAAGBfnPHHxCvPSn8bl5ISdZSLiu7_"
                        action='homepage'
                        verifyCallback={this.verifyCallback}/>
                } 
            </div>
        );
    }
    
}
export default TaskItem;