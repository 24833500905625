import React from 'react';
import qs from 'querystring'
import {getRequestParams} from './utils_android/CookieHelper'
import { showAndroidToast } from './utils_android/AndroidMethods';
class BaseEarnTask extends React.Component {
    constructor(props){
        super(props)
        this.LIKE_PHOTO_TASK_DESC = 'Перейди в Instagram и поставь лайк. Затем возвращайся и получи деньги.'
        this.FOLLOW_PHOTO_TASK_DESC = 'Перейди в Instagram и подпишись на профиль. Затем возвращайся и получи деньги.'
        this.COMMENT_PHOTO_TASK_DESC = 'Перейди в Instagram и напиши положительный комментарий. Затем возвращайся и получи деньги.'
        this.VIDEO_TASK_DESCRIPTION = 'Перейди в Instagram и посмотри видео минимум 7 секунд. Затем возвращайся и получи деньги.'
        this.state = {
            extraOrdersCount : this.props.extraOrdersCount
        }
    }
    componentDidMount(prevProps){
        if (prevProps != this.props){
            this.setState({extraOrdersCount : this.props.extraOrdersCount})
        }
    }
    instaLike = (grade) => {
        var params = getRequestParams();
        params.photo_id = this.props.currentPhotoTask.photo.photo_id;
        params.grade = grade;
        params._id = this.props.currentPhotoTask.photo._id;
        console.log("instaLike params = " + JSON.stringify(params))
        fetch("https://app-coins.ru/api/instaLike", {
            method: 'POST',
            headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
            body: qs.stringify(params)
        }).then((response) => response.json())
        .then((responseData) => {
            if (responseData.response == 1 || responseData.response == 2) {
                this.props.loadNextOrder(true)
            } else {
                this.errorTask()
            }
            console.log("instaLike response = " + JSON.stringify(responseData))
            // else {
            //     this.errorTask()
            // }
        })
    }
    skipOrder = (isSkipedAuto, id) =>{
        this.props.showLoader()
        if (!this.props.isCurrentExtra && this.props.currentPhotoTask.photo.photo_id) {
            this.instaLike(0);
        } else {
            if (isSkipedAuto == undefined) {
                console.log("isSkipedAuto true")
            } else {
                var params2 = getRequestParams();
                fetch("https://app-coins.ru/api/clearExtraWithNulls", {
                    method: 'POST',
                    headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
                    body: qs.stringify(params2)
                }).then((response) => response.json())
                .then((responseData) => {
                   
                })
            }

            var params = getRequestParams();
            params._id = id

            fetch("https://app-coins.ru/api/skipExtraOrder", {
                    method: 'POST',
                    headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
                    body: qs.stringify(params)
                }).then((response) => response.json())
                .then((responseData) => {
                    if (responseData.response == 1 || responseData.response == 2) {
                        
                    }
                    var random =  Math.random()
                    //this.props.loadNextOrder((random >= 0.5))
                    this.props.loadNextOrder(false)
                })
        }
    }
    
    appealClicked = () =>{
        showAndroidToast("Жалоба отправлена")
    }
    errorTask(){
        this.props.hideLoader()
        showAndroidToast("Задание не выполнено. Попробуйте еще раз.")
    }
    openUrl(url) {
        if (typeof window.Android !== "undefined" && window.Android !== null) {
            return window.Android.openUrl(url);
        } else {
            return false
        }
    }
    getSource = (photo) => {
        if (photo == undefined) {
            return "";
        }
        switch (photo.media_type) {
            case 8:
                return photo.carousel_media[0].image_versions2.candidates[0].url;
            case 1:
                return photo.image_versions2.candidates[0].url;
            case 2:
                return photo.image_versions2.candidates[0].url;
            default:
                return "";
        }
    } 
}
export default BaseEarnTask;