import React from 'react';

class FriendsItem extends React.Component {
    constructor(props){
        super(props)
        
    }
    
    render() {
        return (
            <div className="flex justify_content">
                    <div className="flex">
                        <div className="icon_friends_container">
                            <img className="friend_icon rounded" src={this.props.friend.photo_url} alt=""/>
                        </div>

                        <div className="block justify_content text_friends_container">
                            <p className="task_name">{this.props.friend.nick_name}</p>
                            <p className="task_description width_100">Вы получаете 15% от его доходов</p>
                        </div>
                    </div>
                    
            </div>
        );
    }
}
export default FriendsItem;
