import React from 'react';
import empty_user_insta from './insta_icons_tasks/empty_user_insta.svg'
import user_plus_insta_android from './insta_icons_tasks/user_plus_insta_android.svg'
import chevron_next_insta_android from './insta_icons_tasks/chevron_next_insta_android.svg'
import BaseEarnTask from './BaseEarnTask';
import qs from 'querystring'
import {getRequestParams} from './utils_android/CookieHelper'
import { showAndroidToast } from './utils_android/AndroidMethods';
import { ReCaptcha } from 'react-recaptcha-v3'

class ExtraFollow extends BaseEarnTask {
    constructor(props){
        super(props)
        this.state = {
            currentDescription : "",
            isImgError : false

        }
        this.startTaskTime = -1
        window.extraFollow = this
        this.toogleImgError = this.toogleImgError.bind(this);
    }
    toogleImgError(){
        this.setState({isImgError : true})
    }
    onResume(){
        if (this.startTaskTime != -1){
            this.props.showLoader()
            var currentTime = new Date().getTime() / 1000
            if (currentTime - 1 >= this.startTaskTime){
                console.log("onResume true")
                this.checkFollowTask()
            }
        }
    }
    checkFollowTask = () => {
        setTimeout(() => this.instaFollow(), 400);
    }
    doTask = () => {
        this.startTaskTime = new Date().getTime() / 1000
        this.openUrl(this.props.currentExtraOrder.insta_url)

        //test
        //window.open(this.props.currentExtraOrder.insta_url)
    }
    
    instaFollow = () => {
        var params = getRequestParams();
        params.user_id = this.props.currentExtraOrder.order.user_id;
        params._id = this.props.currentExtraOrder.order._id;
        params.user_name = this.props.currentExtraOrder.userInfo ? this.props.currentExtraOrder.userInfo.username : this.props.currentExtraOrder.order.user_name
        console.log("instaFollow params = " + JSON.stringify(params))
        fetch("https://app-coins.ru/api/instaFollow", {
            method: 'POST',
            headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
            body: qs.stringify(params)
        }).then((response) => response.json())
        .then((responseData) => {
            if (responseData.response == 1 || responseData.response == 2) {
                this.props.loadNextOrder(false)
            } else {
                this.errorTask()
            }
            this.startTaskTime = -1
            this.setState({isImgError : false})
            if (responseData.penalty != undefined){
                this.props.showFinedDialog(responseData.penalty)
            }
            console.log("instaFollow" + JSON.stringify(responseData))
        })
    }
    skip = () => {
        this.skipOrder(false, this.props.currentExtraOrder.order._id)
    }
  
    render() {
        return (
            <div className="rate-content__item">
                    <div className="followers_container_appeal">
                        <p className="block_account">За отписку будет штраф</p>
                        <img width="64" height="64" className="placeholder_task icon_64"
                            src={empty_user_insta} alt=""/>
                        <img style={this.state.isImgError ? {opacity : '0'} : {}} onError={this.toogleImgError} className="accounts__photo"
                            src={this.props.currentExtraOrder.userInfo ? this.props.currentExtraOrder.userInfo.profile_pic_url : this.props.currentExtraOrder.order.photo_url}
                            alt="" width="104" height="104"/>
                        <h4 className="accounts__login">
                            {this.props.currentExtraOrder.userInfo ? this.props.currentExtraOrder.userInfo.username : this.props.currentExtraOrder.order.user_name}
                        </h4>
                        <img onClick={this.skip} src={chevron_next_insta_android} className="arrows__item arrows__item_next arrow_item_follower"></img>
                        {/* <p onClick={this.appealClicked} className="appeal_text">Пожаловаться</p> */}
                    </div>


                    <div className="rate-content__actions">
                        <div onClick={this.doTask} className="btn-follow top_10">
                            <img className="btn_task_icon" src={user_plus_insta_android} alt=""/>
                            Подписаться
                        </div>
                        {/* <div onClick={this.checkFollowTask} className="btn-follow top_10">
                            Test
                        </div> */}
                        <div className="coins_rub_lashka rub_plashka">
                            <span
                                className="subscribe_follow_reward">+{this.props.currentExtraOrder.earn_money}₽</span>
                        </div>
                    </div>
                    
                </div>
        );
    }
}
export default ExtraFollow;
