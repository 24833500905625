import React from 'react';
import heart_fine from '../insta_custom_views/insta_icons_tasks/heart_fine.svg'
import { declension } from './utils_android/UrlHelperAndroid';

class FineDialog extends React.Component {
    constructor(props){
        super(props)

    }
    closeTooltip = () =>{
        this.props.toogleFineDialog()
    }
    render(){
      
            return (
                <div className="fine_dialog_background">
                    <div className="fine_dialog">
                        <img className="fine_icon" src={heart_fine}/>
                        <p className="fine_title">Ты разбиваешь наши сердца</p>
                        <p className="fine_text">Мы заметили, что ты отписываешься от аккаунтов после выполнения задания и наложили штраф <span style={{fontWeight : '900'}}>{this.props.coinsCount}₽.</span></p>
                        <p className="fine_text">Не делай больше так, пожалуйста. От этого страдает репутация нашего сервиса.</p>
                        <p onClick={this.closeTooltip} className="fine_ok hover">Понятно</p>
                    </div>
                </div>
                    
            ); 
        
        
    }
}
export default FineDialog;
