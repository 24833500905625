import React from 'react';
import play_task from '../../custom_views/tasks_icons_android/play_task.svg'
import share_task from '../../custom_views/tasks_icons_android/share_task.svg'
import user_task from '../../custom_views/tasks_icons_android/user_task.svg'
import message_task from '../../custom_views/tasks_icons_android/message_task.svg'
import heart_task from '../../custom_views/tasks_icons_android/heart_task.svg'
import chevron_right from '../../custom_views/tasks_icons_android/chevron_right.svg'
import chevron_next_insta_android from '../insta_custom_views/insta_icons_tasks/chevron_next_insta_android.svg'

import BaseEarnTask from './BaseEarnTask';
import {getRequestParams, getCookie} from './utils_android/CookieHelper'

import { getHttpParams, getRequestUrl } from './utils_android/UrlHelperAndroid';
import ErrorTikTokDialog from './ErrorTikTokDialog';
import { getLocalizedStrings } from '../../../LocalizedStrings';
import { showAndroidToast } from './utils_android/AndroidMethods';
const strings = getLocalizedStrings()
class TikTokTask extends BaseEarnTask {
    constructor(props){
        super(props)
        this.state = {
            isShowError : false,
            isLoading : false
        }
        this.startTaskTime = -1
        window.extraView = this
    }
   
    // onResume(){
    //     if (this.startTaskTime != -1){
    //         var currentTime = new Date().getTime() / 1000
    //         if (currentTime - 2 >= this.startTaskTime){
    //             this.props.showLoader()
    //             this.instaViewVideo("1")
    //         } else {
    //             showAndroidToast("Вернитесь и посмотрите видео минимум 7 секунд.")
    //         }
    //     }
    // }
   
    // doTask = () => {
    //     this.startTaskTime = new Date().getTime() / 1000
    //     this.openUrl(this.props.currentPhotoTask.photo.media_url)
    // }
    instaViewVideo = (grade) => {
        var params = getRequestParams();
        params._id = this.props.currentPhotoTask.photo._id;
        params.photo_id = this.props.order.photo_id;
        params.task_id = this.props.order._id;
        params.user_id = getCookie("user_id_tiktok")
        params.token = getCookie("user_id_tiktok")
        console.log("perfomTikTok params = " + JSON.stringify(params))
        fetch("https://app-coins.ru/api/skipTaskTikTok", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            if (responseData.response == 1 || responseData.response == 2) {
                this.props.loadNextOrder()
            } else {
                this.errorTask()
            }
            this.startTaskTime = -1
            console.log("perfomTikTok" + JSON.stringify(responseData))
        })
    }
    skip = () => {
        this.instaViewVideo("0")
    }
  
    onResume(){
        if (this.startTaskTime != -1){
            this.props.showLoader()
            var currentTime = new Date().getTime() / 1000
            // console.log("onResume 0 this.props.order = " + this.props.order)
            // console.log("onResume 1 currentTime = " + currentTime)
            // console.log("onResume 2 this.startTaskTime = " + this.startTaskTime)
            // console.log("onResume 3 result = " + (parseInt(currentTime) - 8 >= parseInt(this.startTaskTime)))
            if (parseInt(currentTime) - (this.props.order.type == 0 ? 8 : 2) >= parseInt(this.startTaskTime)){
                this.checkAndExecuteTask()
                //console.log("onResume 4 checkAndExecuteTask " + currentTime)
                this.startTaskTime = -1
            } else {
                if (this.props.order.type == 0){
                    showAndroidToast("Посмотрите видео не менее 10 секунд")
                } else {
                    this.props.showErrorBanner(this.doTask)
                    //console.log("onResume 5 showErrorBanner = " + currentTime)
                }
                this.startTaskTime = -1
                //console.log("onResume 6 showErrorBanner = " + currentTime)
            }
        }
    }
    doTask = () => {
        this.startTaskTime = new Date().getTime() / 1000
        //console.log("doTask this.startTaskTime = " + this.startTaskTime)
        this.prepareTask()
        this.openUrl(this.props.currentPhotoTask.photo.media_url)

        //this.checkAndExecuteTask()
    }
   
    prepareTask = () => {
        var params = getRequestParams();
        params.task_id = this.props.order._id
        params.user_id = getCookie("user_id_tiktok")
        params.token = getCookie("user_id_tiktok")
        fetch(getRequestUrl() + "prepareTaskTikTok  ", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            //this.checkAndExecuteTask()
            console.log("prepareTask" + JSON.stringify(responseData))
        })
    }
    checkAndExecuteTask = () => {
        if (this.state.isLoading){
            return
        }
        this.setState({isLoading : true})
        var params = getRequestParams();
        params.task_id = this.props.order._id
        params.user_id = getCookie("user_id_tiktok")
        params.token = getCookie("user_id_tiktok")
        console.log("checkAndExecuteTaskTikTok params = " + JSON.stringify(params))
        fetch(getRequestUrl() + "checkAndExecuteTaskTikTok", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            if (responseData.response == 1 || responseData.response == 2) {
                this.props.loadNextOrder()
                this.setState({isShowError : false})
            } else {
                //this.errorTask()
                this.setState({isShowError : true})
            }
            this.setState({isLoading : false})
            console.log("checkAndExecuteTask" + JSON.stringify(responseData))
        })
    }
    getInfoByType = () => {
        var info = {}
        const type = this.props.order.type
        switch (type) {
            case 0:
                info.btn = strings.goToVideo
                info.description = strings.viewTaskDesc
                info.icon = play_task
                break;
            case 1:
                info.btn = strings.goToAuthor
                info.description = strings.followTaskDesc
                info.icon = user_task
                break;
            case 2:
                info.btn = strings.goToVideo
                info.description = strings.likeTaskDesc
                info.icon = heart_task
                break;
            case 3:
                info.btn = strings.goToVideo
                info.description = strings.commentTaskDesc
                info.description_2 = strings.commentTaskDesc2
                info.icon = message_task
                break;
            case 4:
                info.btn = strings.goToVideo
                info.description = strings.shareTaskDesc
                info.description_2 = strings.shareTaskDesc2
                info.icon = share_task
                break;
            default:
                break;
        }
        return info
    }
    checkAgain = () => {
        this.checkAndExecuteTask()
    }
    closeBannerClicked = () => {
        this.setState({isShowError : false, isLoading : false})
    }

    render() {
        const orderInfo = this.getInfoByType()
        return (
            // <div className="rate-content__item">
            //         <div className="photo_task_container">
            //             <img width="64" height="64" className="placeholder_task icon_64"
            //                 src={empty_video_img_insta} alt=""/>
            //             <img className="image_task_holder" src={this.props.currentPhotoTask.photo.photo_path_standart}
            //                 alt="" width="360" height="360"/>
            //             <img onClick={this.skip} src={chevron_next_insta_android} className="arrows__item arrows__item_next arrow_item_follower"></img>
            //             <img src={video_insta_android} className="rate-content__video-icon"></img>
            //         </div>


            //         <div className="rate-content__actions">
            //             <div onClick={this.doTask} className="btn-follow top_10">
            //                 <img className="btn_task_icon" src={this.props.currentPhotoTask.photo.type == 0 ? video_watch_insta_android : user_plus_insta_android} alt=""/>
            //                 {this.props.currentPhotoTask.photo.type == 0 ? "Смотреть видео" : "Подписаться"}
            //             </div>
            //             <div className="coins_rub_lashka rub_plashka">
            //                 <span className="subscribe_follow_reward">+{this.props.currentPhotoTask.photo.earn_money ? this.props.currentPhotoTask.photo.earn_money : "0.02"}₽</span>
            //             </div>
                        
            //         </div>
            // </div>
            <div className="rate-content__item">
                <div className="photo_task_container_tiktok">
                    <div className={this.props.isLoading ? "not_display" : "flex"} >
                        
                        <div className="order_photo_tiktok task_photo_tiktok">
                            <img className={this.props.order.type == 1 ? "avatar_img_tiktok center_img_tiktok" : "task_img_video_tiktok center_img_tiktok"} src={this.props.order.photo_path_standart}/>
                            {/* <img src={this.props.order.photo_path_standart} className="center_img"/> */}
                        </div>

                    </div>
                    <img onClick={this.skip} src={chevron_next_insta_android} className="arrows__item arrows__item_next arrow_item_follower"></img>
                    
                    <div className={this.props.isLoading ? "task_loader_bg" : "not_display"}>
                        <div className="lds-ring loader-earn-container loader_task">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                    
                </div>
                {/* <img className="plus_icon center_img" src={plus}/> */}
                <div onClick={this.doTask} style={{borderRight : 'white', marginBottom : '24px', marginTop : '24px', height : 'auto', textAlign : 'left'}} className="btn-follow_tiktok top_10 flex">
                    <img className="desc_icon_tiktok" src={orderInfo.icon}/>
                    <div>
                        <p style={{marginBottom : 0}} className="task_desc_tiktok">{orderInfo.description}</p>
                        {orderInfo.description_2 != undefined && <p className="task_desc2_tiktok">{orderInfo.description_2}</p>}
                    </div>
                </div>
                <div className="rate-content__actions">
                    <div className="relative">
                        <div onClick={this.doTask} className="btn-follow_tiktok top_10">{orderInfo.btn}</div>
                        {/* <div className="plus_coins_plashka">+{this.props.currentPhotoTask.earn_money}</div> */}
                        <div className="plus_coins_plashka_tiktok">+{this.props.order.earn_money ? this.props.order.earn_money : "0.02"}₽</div>
                    </div>
                    
                </div>
                <p className="reward_desc_tiktok">{strings.reward}</p>
                {this.state.isShowError && <ErrorTikTokDialog isLoading={this.state.isLoading} closeBannerClicked={this.closeBannerClicked} successClicked={this.checkAgain} errorBtn={strings.taskErrorBtn} errorTitle={strings.taskErrorText}/>}
            </div>
        );
    }
}
export default TikTokTask;
