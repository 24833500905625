export const splitTextByTwoSigns = (text,firstSign,secondSign) =>{
    var numFirst = text.indexOf(firstSign)
    var endPartHTML = text.substring(numFirst+firstSign.length)
    var out = endPartHTML;
    var numSecond = endPartHTML.indexOf(secondSign)
    if(numSecond != -1){
        out = endPartHTML.substring(0,numSecond)
    }else{
        return [undefined,numFirst,numFirst+numSecond+2]
    }
    return [out,numFirst,numFirst+numSecond+2]
}
export const getRandomInt = (max) => {
  return Math.floor(Math.random() * max);
}
export const proccessRandomVariants = (textSource) => {
    try{
        var needShuffleArray = false
        
        if(textSource.indexOf("[shuffle]") != -1){
            needShuffleArray = true
            textSource = textSource.replace("[shuffle]","")
        }
        if(textSource.indexOf("]\n\n[") != -1){
            textSource = textSource.split("]\n\n[").join("][")
        }
        if(textSource.indexOf(']</p></div> <div class=\"csv_item_val\"><p class=\"csv_item_container\">[') != -1){
            textSource = textSource.split("]</p></div> <div class=\"csv_item_val\"><p class=\"csv_item_container\">[").join("] [")
        }
        
        
        
        var text = textSource+""
        var cursor = 0
        var lastTextSize = 0
        var startLength = 0
        var result = []
        var lastArrSize = undefined
        while(cursor != -1){
            var out = splitTextByTwoSigns(text,"[","]")
            // console.log("out:"+out)
          if(cursor == out[1] && out[0] != undefined && lastTextSize == out[0].length){
              return ["-2", 0,0]
          }
          cursor = out[1]
          try{
              lastTextSize = out[0].length
          }catch(err){
              
          }
          
          
          if(out[0] != undefined && out[0] != ""){
                var arr = out[0].split(";")
                result.push([arr,startLength+out[1],startLength+out[2]])
                text = text.substring(out[2])
                startLength += out[2]
                if(lastArrSize != undefined && lastArrSize != arr.length && !needShuffleArray){
                      return ["-1", 0, 0]
                }
                lastArrSize = arr.length
          }
        }
        var index = getRandomInt(result[0][0].length)
        var indexFirst = index
        var finalText = ""
        cursor = 0
        result.forEach(item => {
            if(needShuffleArray){
                index = getRandomInt(item[0].length)
            }
            try{
                finalText += textSource.substring(cursor,item[1])
            }catch(err){
                
            }
            
            
            finalText += '<b>'+ item[0][index] +'</b>'
            cursor = item[2]
        });
        
        finalText += textSource.substring(cursor)
        return [finalText,result[0].length, indexFirst]
    } catch(err){
        console.log("err:"+err)
        return [textSource, 0,0]
    }    
}