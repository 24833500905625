/* eslint-disable eqeqeq */
import React from 'react';
import ReactDOM from 'react-dom';
import Navbar from './components_android/NavbarAndroid'
import Tasks from './pages_android/TasksAndroid'
import ActiveTasks from './pages_android/ActiveTasksAndroid'
import FinishedTasks from './pages_android/FinishedTasksAndroid'
import Task from './pages_android/TaskAndroid'
import { View, Panel, Root } from '@vkontakte/vkui';
import '@vkontakte/vkui/dist/vkui.css';
import './css_android/style_android.css';
import { loadReCaptcha } from 'react-recaptcha-v3'
import { ReCaptcha } from 'react-recaptcha-v3'
import 'core-js/features/map';
import 'core-js/features/set';
import Referals from './pages_android/ReferalsAndroid';
import Payouts from './pages_android/PayoutsAndroid';
import GetPayout from './pages_android/GetPayoutAndroid';
import Support from './pages_android/SupportAndroid';
import { getUrlParameter, getHttpParams } from './pages_android/custom_views/insta_custom_views/utils_android/UrlHelperAndroid';
import {saveRegistrCookie, getGoogleId, getRequestParams, getIsUpmobKey, isShowClose, saveBundle, saveApiKey, setCookie} from './pages_android/custom_views/insta_custom_views/utils_android/CookieHelper'
import Loader from './pages_android/custom_views/insta_custom_views/utils_android/LoaderAndroid';
import { registrationFailed } from './pages_android/custom_views/insta_custom_views/utils_android/AndroidMethods';
import AuthInsta from './pages_android/AuthInstaAndroid';
import EarnInsta from './pages_android/EarnInstaAndroid';
import CustomTask from './pages_android/CustomTask';
import EarnTikTok from './pages_android/EarnTikTok';
import MaterialMenuAndroid from './components_android/MaterialMenuAndroid';
import CustomLoginsTask from './pages_android/CustomLoginsTask';
import { YMInitializer } from 'react-yandex-metrika';
import ym from 'react-yandex-metrika';
import amplitude from 'amplitude-js'
window.$moneyCount = 0.0
class Application extends React.Component {
   
    constructor(){
        super()
        var hashId = (getGoogleId("google_user_id") == null || getGoogleId("google_user_id") == undefined || getGoogleId("google_user_id") == "")
        var hasApiKey = (getUrlParameter("api_key") != null && getUrlParameter("api_key") != undefined && getUrlParameter("api_key") != "")
        var api_key = getUrlParameter("api_key")
        var isUpmob = hasApiKey ? (api_key == "upmob" || api_key == "lexus" || api_key == "QWT" || api_key == "QWT2") ? true : false : true
        //заменить еще в кукисах
        if (api_key != null && api_key != undefined && api_key != ""){
            saveApiKey(api_key)
        }
        this.state = {
            count_money_r : 0.0,
            nick_name : "",
            photo_url : "",
            email : "",
            google_user_id : "",
            info : {},
            done : true,
            timestamp : "",
            isUpmob : (hashId ? isUpmob : getIsUpmobKey()),
            ref_id : "",
            isClose : (hashId ? hasApiKey ? getUrlParameter("api_key") : false : isShowClose()),
            activeView : 'tasks',
            currentTask : {},
            backView : undefined,
            isMenuOpened : false,
            instagram_user_name : "",
            isLoadCaptcha : false,
            isNeedInstaCaptcha : false,

            isRegistered : (hashId ? false : true)
        }
       
        this.onTaskClicked = this.onTaskClicked.bind(this);
        this.onBack = this.onBack.bind(this);
        this.onMenuClicked = this.onMenuClicked.bind(this);
        this.toogleMenu = this.toogleMenu.bind(this);
        this.onPayoutClicked = this.onPayoutClicked.bind(this);
        this.openViewBack = this.openViewBack.bind(this);
        console.log("location = " + window.location.href)
        console.log("isRegistered = " + (hashId ? true : false))
       
    }
    
    componentDidMount(){
        console.log("componentDidMount index")
        // loadReCaptcha("6LdFRuEUAAAAAGBfnPHHxCvPSn8bl5ISdZSLiu7_")
        amplitude.getInstance().init("756d4a7f29c62a978d16622022c4e244");
        if (getUrlParameter("google_user_id")){
            setCookie("google_user_id", getUrlParameter("google_user_id"))
            setCookie("device_id", getUrlParameter("device_id"))
            setCookie("api_key", getUrlParameter("api_key"))
            this.getMyInfo()
            return
        }
        if (getGoogleId("google_user_id") == null || getGoogleId("google_user_id") == undefined || getGoogleId("google_user_id") == "") {
            // this.setState({isLoadCaptcha : true})
            this.registerUser()
            console.log("componentDidMount loadCaptcha")
        } else {
            this.getMyInfo()
            console.log("componentDidMount getMyInfo")
        }
       
        
    }
    
    toogleMenu(isOpened){
        if (isOpened != undefined){
            this.setState({isMenuOpened : isOpened})
        } else {
            this.setState({isMenuOpened : !this.state.isMenuOpened})
        }
    }

    verifyCallback = (recaptchaToken) => {
        this.setState({isLoadCaptcha : false})
        if (getGoogleId("google_user_id") == null || getGoogleId("google_user_id") == undefined || getGoogleId("google_user_id") == "") {
            this.registerUser(recaptchaToken)
        }
    }
 
    registerUser = (recaptchaToken) => {
        var params = getRequestParams()
        var device_id = getUrlParameter('device_id')
        var token_google = getUrlParameter('token_google')
        var uniq_user_id = getUrlParameter('uniq_user_id')
        var api_key = getUrlParameter('api_key')
        var bundle = getUrlParameter('bundle')
        if (bundle != "" && bundle != undefined && bundle != null){
            saveBundle(bundle)
        }
        params.device_id = device_id
        params.token_google = token_google
        params.easy_money_user_id = uniq_user_id
        params.api_key = api_key
        if (recaptchaToken){
            params.timestamp = recaptchaToken
        }
        params.bundle = bundle
        if (api_key == "lexus"){
            params.promo_id = "945113"
        } else if (api_key == "QWT"){
            params.promo_id = "2554224"
        } else if (api_key == "QWT2"){
            params.promo_id = "2719566"
        }
        fetch("https://app-coins.ru/api/registerEmployer", getHttpParams(params)).then((response) => response.json())
            .then((responseData) => {
                if (responseData.status == "OK") {
                    if (responseData.response == 1){
                        ym(86575405,'reachGoal','register')
                        amplitude.getInstance().logEvent('register');
                    } else {
                        ym(86575405,'reachGoal','duplicateRegister')
                        amplitude.getInstance().logEvent('duplicateRegister');

                    }
                    saveRegistrCookie(responseData, device_id, uniq_user_id, api_key)
                    this.setState({isRegistered : true})
                    this.getMyInfo()
                } else {
                    registrationFailed(responseData.status)
                    this.setState({done : true})
                }
                
            })
    }
   
    
    getMyInfo(){
        this.setState({done : false})
        var params = getRequestParams()
        fetch("https://app-coins.ru/api/getMyInfoEmployer", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            if (responseData.response === 1){
                this.setState({count_money_r : responseData.employer.count_money_r,
                    nick_name : responseData.employer.nick_name,
                    photo_url : responseData.employer.photo_url,
                    email : responseData.employer.email,
                    google_user_id : responseData.employer.google_user_id,
                    min_transfer : responseData.min_transfer,
                    wallet_new : responseData.employer.wallet_new,
                    info : responseData.employer,
                    isUpmob : getIsUpmobKey(),
                    ref_id : responseData.employer._id,
                    isClose : isShowClose(),
                    isNeedInstaCaptcha : (responseData.employer.count_out_likes <= 100 && responseData.employer.count_out_followers <= 50),
                    instagram_user_name : responseData.employer.instagram_user_name ? responseData.employer.instagram_user_name : ""
                })
                this.setState({done : true})
            }
            // console.log("getMyInfoEmployer = " + JSON.stringify(responseData))
        })
    }
    
    updateMoney = (money) => {
        var countMoney = this.state.count_money_r + parseFloat(money)
        this.setState({count_money_r : countMoney})
    }
    setMoney = (money) => {
        this.setState({count_money_r : money})
    }
    onTaskClicked(task, activeView, backView){
        console.log("onTaskClicked task = ", task)
        this.setState({currentTask : task, activeView : activeView, backView : backView})
    }
    openViewBack(activeView, backView){
        this.setState({activeView : activeView, backView : backView})
       console.log("openViewBack back = " + backView)
       this.getMyInfo()
    }
    onPayoutClicked(){
        this.setState({activeView : 'payout', backView : 'payouts'})
    }
    onMenuClicked(viewId){
        this.setState({activeView : viewId, backView : undefined, currentTask: {}})
        if (viewId == "active" || viewId == "finished" || viewId == "tasks"){
            this.getMyInfo()
        }
    }
    onBack(viewId){
        if (viewId == "active"){
            this.getMyInfo()
        }
        this.setState({activeView : viewId, backView : undefined})
    }
   
    render() {
        
        if (!this.state.done){
            return(<Loader/>) 
        } else return (
            <div>
                <YMInitializer accounts={[86575405]} options={{webvisor: true, clickmap:true, trackLinks:true, accurateTrackBounce:true}} />
                 {this.state.isLoadCaptcha && <ReCaptcha
                    sitekey="6LdFRuEUAAAAAGBfnPHHxCvPSn8bl5ISdZSLiu7_"
                    action='homepage'
                    verifyCallback={this.verifyCallback}
                />}
                <div className="app">
                    <MaterialMenuAndroid activeView={this.state.activeView} toogleMenu={this.toogleMenu} isMenuOpened={this.state.isMenuOpened} onMenuClicked={this.onMenuClicked} isUpmob={this.state.isUpmob} count_money_r={this.state.count_money_r} name={this.state.nick_name} photo={this.state.photo_url} email={this.state.email} google_user_id={this.state.google_user_id}/>
                    <div className = "main">
                        <Navbar toogleMenu={this.toogleMenu} onBack={this.onBack} backView={this.state.backView} isClose={this.state.isClose} isUpmob={this.state.isUpmob} count_money_r={this.state.count_money_r}/>
                        <section>
                            
                            <Root activeView={this.state.activeView}>
                                <View id="tasks" activePanel="tasks_panel">
                                    <Panel id="tasks_panel">
                                        {this.state.isRegistered && <Tasks updateMoney={this.updateMoney} wallet_new={this.state.wallet_new} count_money_r={this.state.count_money_r} readyToTasks={this.state.isRegistered} instaUsername={this.state.instagram_user_name} openViewBack={this.openViewBack} onTaskClicked={this.onTaskClicked} isUpmob={this.state.isUpmob}/>}
                                    </Panel>
                                </View>
                                <View id="task" activePanel="task_panel">
                                    <Panel id="task_panel">
                                        <Task onBack={this.onBack} task={this.state.currentTask} updateMoney={this.updateMoney}/>
                                    </Panel>
                                </View>
                                <View id="active" activePanel="active_panel">
                                    <Panel id="active_panel">
                                        <ActiveTasks onTaskClicked={this.onTaskClicked} info={this.state.info}/>
                                    </Panel>
                                </View>
                                <View id="finished" activePanel="finished_panel">
                                    <Panel id="finished_panel">
                                        <FinishedTasks onTaskClicked={this.onTaskClicked} info={this.state.info}/>
                                    </Panel>
                                </View>
                                <View id="referals" activePanel="referals_panel">
                                    <Panel id="referals_panel">
                                        <Referals ref_id={this.state.ref_id}/>
                                    </Panel>
                                </View>
                                <View id="payouts" activePanel="payouts_panel">
                                    <Panel id="payouts_panel">
                                        <Payouts onPayoutClicked={this.onPayoutClicked}/>
                                    </Panel>
                                </View>
                                <View id="payout" activePanel="payout_panel">
                                    <Panel id="payout_panel">
                                        <GetPayout wallet_new={this.state.wallet_new} updateMoney={this.updateMoney} min_transfer={this.state.min_transfer} count_money_r={this.state.count_money_r}/>
                                    </Panel>
                                </View>
                                <View id="support" activePanel="support_panel">
                                    <Panel id="support_panel">
                                        <Support isUpmob={this.state.isUpmob} google_user_id={this.state.google_user_id}/>
                                    </Panel>
                                </View>
                                <View id="auth_insta" activePanel="auth_insta_panel">
                                    <Panel id="auth_insta_panel">
                                        <AuthInsta openViewBack={this.openViewBack}/>
                                    </Panel>
                                </View>
                                <View id="earn_insta" activePanel="earn_insta_panel">
                                    <Panel id="earn_insta_panel">
                                        <EarnInsta isNeedInstaCaptcha={this.state.isNeedInstaCaptcha} activeView={this.state.activeView} openViewBack={this.openViewBack} setMoney={this.setMoney}/>
                                    </Panel>
                                </View>
                                <View id="tiktok_task" activePanel="tiktok_task_panel">
                                    <Panel id="tiktok_task_panel">
                                         <EarnTikTok setMoney={this.setMoney}/>
                                    </Panel>
                                </View>
                                <View id="custom_task" activePanel="custom_task_panel">
                                    <Panel id="custom_task_panel">
                                        <CustomTask task={this.state.currentTask} openViewBack={this.openViewBack} updateMoney={this.updateMoney}/>
                                    </Panel>
                                </View>
                                <View id="login_task" activePanel="login_task_panel">
                                    <Panel id="login_task_panel">
                                        <CustomLoginsTask order={this.state.currentTask} openViewBack={this.openViewBack} updateMoney={this.updateMoney}/>
                                    </Panel>
                                </View>
                            </Root>
                        </section>
                    </div>
                
                </div>
             </div>
            
        );
        
        //return ( <GetPayout wallet_new={this.state.wallet_new} updateMoney={this.updateMoney} min_transfer={this.state.min_transfer} count_money_r={this.state.count_money_r}/>)
    }
    
}



ReactDOM.render(<Application/>,
    document.getElementById('root')
);
window.onResume = () => {
    console.log("onResume index")
    if (window.firstTask != undefined){
        window.firstTask.onResume();
    }
    if (window.secondTask != undefined){
        window.secondTask.onResume();
    }
    if (window.thirdTask != undefined){
        window.thirdTask.onResume();
    }
    if (window.fouthTask != undefined){
        window.fouthTask.onResume();
    }
    if (window.fifthTask != undefined){
        window.fifthTask.onResume();
    }

    if (window.extraView != undefined){
        window.extraView.onResume();
    }
    if (window.photoTask != undefined){
        window.photoTask.onResume();
    }
    if (window.extraFollow != undefined){
        window.extraFollow.onResume();
    }
    if (window.extraComment != undefined){
        window.extraComment.onResume();
    }

    if (window.customTask != undefined){
        window.customTask.onResume();
    }
    if (window.sdkTask != undefined){
        window.sdkTask.onResume();
    }
   
}
window.handleImage = (image) => {
    if (window.tasks != undefined){
        window.tasks.handleImage(image);
    }
}


