import React from 'react';

class PayoutItem extends React.Component {
    constructor(props){
        super(props)
        
    }
    
    render() {
        return (
            <div className="block">
                    <div className="flex justify_content payout_line">
                        <p className="task_name">{this.props.payout.time}</p>
                        <div className="flex">
                            <p className="task_name right_32">{this.props.payout.amount}₽</p>
                            <p className={this.props.payout.status_type == 0 || this.props.payout.status_type == 2 ? 'payout_green' : 'payout_red'}>
                                {this.props.payout.status_text}</p>
                        </div>
                    </div>
                    <div className="line_full"></div>
            </div>
        );
    }
}
export default PayoutItem;
