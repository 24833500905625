import React from 'react';

class ErrorBanner extends React.Component {
    constructor(props){
        super(props)

    }
    
    render(){
      
            return (
                <div className="error_bg">
                    <div className="error_banner_container">
                            <img onClick={() => this.props.closeBannerClicked()} className="close_icon_error" src="/icons_android/x_android.svg"
                                alt=""/>
                            <p className="task_name">{this.props.errorTitle}</p>
                            <p onClick={() => this.props.successClicked()}className="error_btn">{this.props.errorBtn}</p>
                    </div>  
                </div>
            ); 
        
        
    }
}
export default ErrorBanner;