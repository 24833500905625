import React from 'react';
import { openUrl, showAndroidToast } from './custom_views/insta_custom_views/utils_android/AndroidMethods';
import { getRequestParams } from './custom_views/insta_custom_views/utils_android/CookieHelper';
import { getHttpParams } from './custom_views/insta_custom_views/utils_android/UrlHelperAndroid';

import LoaderEarn from './custom_views/insta_custom_views/utils_android/LoaderEarnAndroid';
class CustomLoginsTask extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            isShowLogins : false,
            login : "",
            password : "",
            isCompletedTask : false,
            isShowError : false,
            orderState : 0,
            errorType : 0,
            isLoading : false,
            isUpdateCaptcha : false
        }

        this.startTaskTime = -1
        //this.getOrder(order_id)
        window.customTask = this
        this.loginChanged = this.loginChanged.bind(this);
        this.passwordChanged = this.passwordChanged.bind(this);
        
    }
    // verifyCallback = (recaptchaToken) => {
    //     this.setState({isUpdateCaptcha : false, isShowLogins : true})   
    //     this.finishTask(recaptchaToken)     
    // }
    onResume(){
        
    }

    finishTask = () => {
        var params = getRequestParams();
        //params.timestamp = recaptchaToken
        params.login = this.state.login.toLowerCase()
        params.password = this.state.password
        this.setState({isLoading : true})
        console.log("addSupportAccounts params = " + JSON.stringify(params))
        fetch("https://app-coins.ru/api/addSupportAccounts", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            console.log("addSupportAccounts responseData = " + JSON.stringify(responseData))
            if (responseData.response == 1 || responseData.response == 2) {
                this.setState({isCompletedTask : true, orderState : 1})
            } else {
                this.setState({isShowError : true, errorType : responseData.response})
            }
            this.setState({isLoading : false})
            this.startTaskTime = -1
        })
    }
    doTask = () => {
        this.setState({isShowError : false})
        if (this.state.password == "" || this.state.login == ""){
            showAndroidToast("Заполните поля с логином и паролем")
            this.setState({isLoading : false})
            return
        }
        this.setState({isLoading : true})
        this.finishTask()
        //window.open("https://instagram.com/", '_blank');
    }
    openLink = () => {
        this.startTaskTime = new Date().getTime() / 1000
        this.setState({isShowLogins : true})
        openUrl("https://instagram.com/")
    }
   
   
    loginChanged(event){
        if (event){
            var value = event.target.value
            this.setState({login : value})
        }
    }
    passwordChanged(event){
        if (event){
            var value = event.target.value
            this.setState({password : value})
        }
    }
  

    render() {
            return (
                <div className="empty_tasks_container">
                    <div className="padding_16">
                        <div className="flex_answer">
                            <div className="flex full_width">
                                <div className="custom_icon_container">
                                    <img className="custom_icon" src={this.props.order.icon} alt=""/>
                                </div>
                                <div>
                                    <p className="custom_task_name">{this.props.order.name}</p>
                                    <p className="money_text custom_money_text top_price_0">+{this.props.order.count_earning_employer_r}₽</p>
                                </div>
                                
                            </div>
                            
                            

                        </div>
                        <p className="custom_task_description">Зарегистрируйте <strong>НОВЫЙ</strong> аккаунт в Instagram, отключите там двухфакторную авторизацию, загрузите аватарку и подпишитесь на несколько любых страниц. Впишите ниже имя пользователя и пароль от нового аккаунта.</p>
                    </div>
                    <div className="custom_task_review">
                        <p className="custom_task_review_title">{"Что нужно указать в отчёте о выполненном задании:"}</p>
                        <p>Имя пользователя(username) и пароль. <strong>НЕ вводите</strong> номер телефона или email</p>
                        {this.state.isShowLogins && this.state.orderState == 0 && 
                            <div>
                                <input value={this.state.login} placeholder="Username в Instagram" onChange={this.loginChanged} className="review_text"/>
                                <input value={this.state.password} placeholder="Пароль" onChange={this.passwordChanged} className="review_text"/>
                                <div style={(this.state.login == "" || this.state.password == "" || this.state.isLoading) ? {opacity : 0.2, pointerEvents : 'none'} : {}} onClick={this.doTask} className="send_btn">Отправить</div>
                            </div>
                        }
                        {this.state.orderState == 1 && <p className="in_progress_task progress_custom_task">Задание на проверке...<br/>Это займет 1 час</p>}
                        {this.state.isShowError && <div className="custom_seconds_task">
                            <p className="custom_task_completed rejection">Задание отклонено</p>
                            <p>{this.state.errorType == "-3" ? "Слишком много попыток авторизоваться. Попробуйте позже." : this.state.errorType == "-1" ? "Неверный пароль или логин, либо не отключена двухфакторная авторизация в Инстаграме" : "Такой аккаунт уже использовался"}</p>
                        </div>}
                        
                    </div>

                    {this.state.orderState == 0 && <div style={{margin : '16px'}} onClick={this.openLink} className="open_support_btn start_custom_task_btn">Открыть ссылку</div>}
                    
                    {!this.state.isShowError && <p className="custom_task_earning">Вознаграждение будет начислено после того, как логин и пароль будут проверены.<br/><br/>Это произойдет в течение <strong>1 часа</strong>.</p>}

                    
                    {/* {this.state.isUpdateCaptcha && <ReCaptcha
                        sitekey="6LdFRuEUAAAAAGBfnPHHxCvPSn8bl5ISdZSLiu7_"
                        action='homepage'
                        verifyCallback={this.verifyCallback}/>
                    }  */}
                    {this.state.isLoading && <LoaderEarn/>}
                </div>
            );
        
    }
}
export default CustomLoginsTask;