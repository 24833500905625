import React from 'react';
import qs from 'querystring'
import {getRequestParams, saveInstaCookie, getInstaLogin} from './custom_views/insta_custom_views/utils_android/CookieHelper'
import login_insta_android from './icons_insta_android/login_insta_android.svg'
import close from './icons_insta_android/close_insta_android.svg'
import {DebounceInput} from 'react-debounce-input';
import AuthDropdown from './custom_views/insta_custom_views/AuthDropdownAndroid';
import Loader from './custom_views/insta_custom_views/utils_android/LoaderAndroid';
import { loadReCaptcha } from 'react-recaptcha-v3'
import { ReCaptcha } from 'react-recaptcha-v3'
import { getUrlParameter, getHttpParams } from './custom_views/insta_custom_views/utils_android/UrlHelperAndroid';
var isAutoLoaded = false
class AuthInsta extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            users: [],
            done : true,
            isShowDropdown : false,
            pickedUser : {},
            errorText : "",
            isShowError : false,
            isSearching : false,
            usernameText : "",
            isLoading : false
        }
        this.isAutoLogin = true
    }
    
    componentDidMount(){
        var login = getInstaLogin()
        if (login) {
            //this.props.openViewBack("earn_insta", "tasks")
         }
    }
    instaSearch = (event, isPick, recaptchaToken) =>{
            var text = this.state.usernameText
            this.setState({searchValue: text, isShowError : false, pickedUser : {}})
            if (text.includes("+")) {
                this.setState({isShowError : true, errorText : 'Не вводите номер телефона. Используйте username из Инстаграм.'})
               return
             }
             if (text.includes("@")) {
                this.setState({isShowError : true, errorText : 'Не вводите email. Используйте username из Инстаграм.'})
               return
             }
             if (text.trim().includes(" ")) {
                this.setState({isShowError : true, errorText : 'Пробелы в имени не допустимы.'})
               return
             }
             if (text == undefined || text == "" || text == " "){
                return
             }
        
        var params = getRequestParams()
        if (isPick){
            params.login = getInstaLogin()
        } else {
            params.login = text
        }
        params.secretFlag = "1"
        console.log("instaSearch params = " + JSON.stringify(params))
        this.setState({isLoading : true})
        fetch("https://app-coins.ru/api/instaSearch", getHttpParams(params)).then((response) => response.json())
            .then((responseData) => {
                this.setState({isSearching : false})
                if (responseData.status == "OK") {
                    var users = responseData.user.users
                    this.setState({users : users})
                    
                    if (users.length > 0){
                        this.setState({pickedUser : users[0]})
                        this.registerUser(users[0])
                    } else {
                        this.setState({done : true, isLoading : false, isShowError : true, errorText : "Такой юзер не найден. Проверьте правильность написания вашего имени пользователя."})
                    }
                } else {
                    this.setState({done : true, isLoading : false, isShowError : true, errorText : "Такой юзер не найден. Проверьте правильность написания вашего имени пользователя."})
                }
                //console.log("instaSearch params = " + JSON.stringify(params))
                if (responseData.user && responseData.user.users.length == 0){
                    this.setState({isShowDropdown : false})
                }
            })       
    }

    registerUser = (pickedUser) => {
            this.setState({isShowError : false, done : false})
            var params = getRequestParams()
            params.instagram_user_name = pickedUser.username
            params.instagram_uid = pickedUser.pk
            params.secretFlag = "1"
            fetch("https://app-coins.ru/api/addInstaInfo", {
                    method: 'POST',
                    headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
                    body: qs.stringify(params)
                }).then((response) => response.json())
                .then((responseData) => {
                    if (responseData.response == 1 || responseData.response == 2) {
                        saveInstaCookie(pickedUser.username, pickedUser.pk)
                        if(pickedUser.profile_pic_url.includes("44884218_345707102882519_2446069589734326272")){
                            this.setState({isShowError : true, errorText : "У вашего профиля в Инстаграм должен быть установлен аватар(фотография профиля)."})
                        } else {
                            //window.location.href = '/earn_insta?back=tasks&testReactJS=1'
                            this.props.openViewBack("earn_insta", "tasks")
                        }                
                    }
                    this.setState({done : true, isLoading : false})
                })       
        
    }
    userPicked = (user) => { 
        this.setState({isShowDropdown : false, pickedUser : user, searchValue : user.username})
    }
    clearPickedUser = () => {
        this.setState({isShowDropdown : false, pickedUser : {}, searchValue : ""})
    }
    usernameTextChanged = (event) => {
        if (event){
            this.setState({usernameText : event.target.value})
        }
    }
    render() {
        if (this.state.done){
            return (
                <div className="white_bg" id="authControllerID">
    
                    <div className="form-wrapper">
                        <h1 className="h1 form__header">Авторизация</h1>
                        <div ng-style="loginPassStyle">
                            <p className="form__description">Введите имя пользователя от Instagram, чтобы начать зарабатывать. Пароль не требуется.</p>
                            <div className="select">
                                <img className="select__img" src={this.state.pickedUser.profile_pic_url ? this.state.pickedUser.profile_pic_url : login_insta_android} alt=" "/>
                                <input
                                    className="form__input form__input_login"
                                    value={this.state.usernameText}
                                    placeholder="Логин Instagram"
                                    minLength={2}
                                    onChange={this.usernameTextChanged}
                                     />
                            
                                {/* <input value={this.state.searchValue} ng-change='searchInInsta()'
                                    id="loginInput" className="form__input form__input_login" type="text" name="login"
                                    placeholder="Логин Instagram" required/> */}
                                <img onClick={this.clearPickedUser} className={this.state.pickedUser.username ? "select__clear" : "not_display"} src={close} alt=" "/>
                                {/* {(this.state.isShowDropdown || this.state.isSearching) && <AuthDropdown isSearching={this.state.isSearching} userPicked={this.userPicked} users={this.state.users}/>} */}
                            </div>
                            <span className={this.state.isShowError ? "form__error" : "not_display"}>{this.state.errorText}</span>
                
                            <div onClick={this.instaSearch}
                                className="btn btn_purple form__btn sign_in_btn">ВОЙТИ
                                {this.state.isLoading && <Loader />}
                            </div>
                            
                        </div>
                    </div>
    
                   {/* {this.state.isUpdateCaptcha && <ReCaptcha
                        sitekey="6LdFRuEUAAAAAGBfnPHHxCvPSn8bl5ISdZSLiu7_"
                        action='homepage'
                        verifyCallback={this.verifyCallback}/>
                    }  */}
                
    
            </div>
            );
        } else {
            return (<Loader/>)
        }
        
    }
}
export default AuthInsta;