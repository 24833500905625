import React from 'react';
import {getRequestParams, saveInstaCookie, getInstaLogin, getCookie, setCookie} from './custom_views/insta_custom_views/utils_android/CookieHelper'
import login_insta_android from './icons_insta_android/login_insta_android.svg'
import close from './icons_insta_android/close_insta_android.svg'
import {DebounceInput} from 'react-debounce-input';
import AuthDropdown from './custom_views/insta_custom_views/AuthDropdownAndroid';
import Loader from './custom_views/insta_custom_views/utils_android/LoaderAndroid';
import { getUrlParameter, getHttpParams } from './custom_views/insta_custom_views/utils_android/UrlHelperAndroid';
var isAutoLoaded = false
class AuthTikTok extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            users: [],
            done : true,
            pickedUser : {},
            errorText : "",
            isShowError : false,
            isSearching : false,
            searchValue : ""
        }
    }
    searchValueChanged = (event) => {
        if (event){
            this.setState({searchValue : event.target.value})
        }
    }
     
    tikTokGetInfo = () =>{
        var text = this.state.searchValue
        
            this.setState({searchValue: text, isShowError : false, pickedUser : {}})
            if (text.includes("+")) {
                this.setState({isShowError : true, errorText : 'Не вводите номер телефона. Используйте username из Инстаграм.'})
               return
             }
             if (text.includes("@")) {
                this.setState({isShowError : true, errorText : 'Не вводите email. Используйте username из Инстаграм.'})
               return
             }
             if (text.trim().includes(" ")) {
                this.setState({isShowError : true, errorText : 'Пробелы в имени не допустимы.'})
               return
             }
             if (text == undefined || text == "" || text == " "){
                return
             }
        
        var params = getRequestParams()
        params.username = text
        params.secretFlag = "1"
        this.setState({done : false, isShowError : false})
        fetch("https://app-coins.ru/api/getInfoUserTikTok", getHttpParams(params)).then((response) => response.json())
            .then((responseData) => {
                if (responseData.response == "-1"){
                    this.setState({done : true, isShowError : true, errorText : "Такой логин не найден. Проверьте правильность, введенного имени из Tik Tok"})
                } else {
                    setCookie('uniqueIdTikTok', responseData.uniqueId)
                    setCookie('user_id_tiktok', responseData.id)
                    setCookie('is_authed_tiktok5', "true")
                    this.registerUser()
                }
                console.log("getInfoUserTikTok = " + JSON.stringify(responseData))
            })       
    }

    registerUser = () => {
            this.setState({isShowError : false, done : false})
            var params = getRequestParams()
            params.user_name = getCookie("uniqueIdTikTok")
            
            fetch("https://app-coins.ru/api/registerUserTikTok", getHttpParams(params)).then((response) => response.json())
                .then((responseData) => {
                    if (responseData.response == 1 || responseData.response == 2) {
                        this.props.authFinished()        
                    } else {
                        this.setState({isShowError : true, errorText : "Вы уже заходили под другим именем из TikTok. Менять аккаунт нельзя."})
                    }
                    this.setState({done : true})
                    console.log("registerUserTikTok = " + JSON.stringify(responseData))
            })       
       
    }
    userPicked = (user) => { 
        this.setState({pickedUser : user, searchValue : user.username})
    }
    clearPickedUser = () => {
        this.setState({pickedUser : {}, searchValue : ""})
    }
    render() {
        if (this.state.done){
            return (
                <div className="white_bg" id="authControllerID">
    
                    <div className="form-wrapper">
                        <h1 className="h1 form__header">Авторизация</h1>
                        <div ng-style="loginPassStyle">
                            <p className="form__description">Введите имя пользователя от TikTok, чтобы начать зарабатывать. Пароль не требуется.</p>
                            <div className="select">
                                <DebounceInput
                                    className="form__input form__input_login"
                                    value={this.state.searchValue}
                                    placeholder="Логин TikTok"
                                    minLength={2}
                                    onChange={this.searchValueChanged}
                                    debounceTimeout={100}/>
                           
                                <img onClick={this.clearPickedUser} className={this.state.pickedUser.username ? "select__clear" : "not_display"} src={close} alt=" "/>
                            </div>
                            <span className={this.state.isShowError ? "form__error" : "not_display"}>{this.state.errorText}</span>
                
                            <div onClick={this.tikTokGetInfo} className="btn btn_purple form__btn sign_in_btn">ВОЙТИ</div>
                        </div>
                    </div>
                    {!this.state.done && <Loader/>}
                </div>
            );
        } else {
            return (<Loader/>)
        }
        
    }
}
export default AuthTikTok;