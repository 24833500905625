import React from 'react';
class EarnCheckDialog extends React.Component {
    constructor(props){
        super(props)
       
    }
    
    render() {
        return (
            <div className={this.props.isShowCheckDialog ? 'appeal_container_bg_shown' : 'appeal_container_bg_hiden sdk_appeal_header'}>
                <div onClick={this.props.toogleCheckDialog} className={this.props.isShowCheckDialog ? 'animateAppealBgShow' : 'animateAppealBgHide sdk_appeal_header'}>

                </div>
                <div className={this.props.isShowCheckDialog ? 'appeal_container_shown' : 'appeal_container_hide sdk_appeal_header'}>
                    <div style={{background : "#E3F2FF"}} className="appeal_header">
                        <p className="appeal_header_text">🤓 Как работает проверка заданий</p>
                    </div>
                   
                    <div className="block padding_16">
                        <p>Проверяется не каждое действие, поэтому после каждого действия засчитываются деньги.<br/><br/>Раз в несколько часов происходит проверка всех выполненных действий и если были нарушения, (например, невыполнение заданий или отписка), тогда все заработанные деньги за этот период обнуляются, даже если какие-то задания были выполнены.<br/><br/>Если вы ошиблись и случайно что-то не так сделали, штраф применён не будет.</p>
                        <button onClick={this.props.toogleCheckDialog}className="task_btn center_hor">ПОНЯТНО</button>
                    </div>
                </div>
            </div>
        );
    }
}
export default EarnCheckDialog;