/* eslint-disable eqeqeq */
import React from 'react';
import ErrorBanner from './custom_views/ErrorBannerAndroid';
import FirstTask from './custom_views/FirstTaskAndroid';
import qs from 'querystring'
import {getRequestParams, getBundle} from './custom_views/insta_custom_views/utils_android/CookieHelper'
import {getUrlParameter} from './custom_views/insta_custom_views/utils_android/UrlHelperAndroid'
import SecondTask from './custom_views/SecondTaskAndroid';
import Loader from './custom_views/insta_custom_views/utils_android/LoaderAndroid';
import ThirdTask from './custom_views/ThirdTaskAndroid';
import FouthTask from './custom_views/FouthTaskAndroid';
import FifthTask from './custom_views/FifthTaskAndroid';
import AppealTask from './custom_views/AppealTaskAndroid';
import SdkTaskAndroid from './custom_views/SdkTaskAndroid';
import SdkAppealTask from './custom_views/SdkAppealTask';
import ym from 'react-yandex-metrika';


class Task extends React.Component {
    constructor(props){
        super(props)
        this.order_id = (this.props.task.order_id ? this.props.task.order_id : this.props.task._id)
        this.progressArray = this.props.task.progressArray
        this.progress_id = this.props.task._id
        this.modified = this.props.task.modified.split('T')[0]
        this.params = getRequestParams()
        this.params.progress_id = this.progress_id
        this.params.order_id = this.order_id
        this.params.bundle = getBundle()
        console.log("this.params = " + JSON.stringify(this.params))
        console.log("this.props.task = " + JSON.stringify(this.props.task))
     
        this.isShowError = false
        var isNewTask = true
        if (this.progressArray && this.progressArray != ""){
            isNewTask = false
        }
        this.state = {
            order: this.props.task,
            prices: {},
            done : true,
            hasOrder : true,
            isShowError : false,
            task2Enabled : false,
            task3Enabled : false,
            task4Enabled : false,
            task5Enabled : false,

            params : this.params,

            task2 : true,
            task3 : false,
            task4 : false,
            task5 : false,
            isNewTask : isNewTask,
            isRedirect : false,
            sdkTasks : [],
            isSdkTaskEnabled : false,

            isShowSdkAppeal : false,
            sdkAppealInfo : {task_id : "", text : ""},
            appPosition : "",
            searchText : ""
           
        }
        this.loadedOrder = undefined
        //this.getOrder()
        this.toggleSdkAppeal = this.toggleSdkAppeal.bind(this);

    }
    
    getPriceConfig = () =>{
        fetch("https://app-coins.ru/api/getPriceConfig", {
            method: 'POST',
            headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
            body: qs.stringify(this.state.params)
        }).then((response) => response.json())
        .then((responseData) => {
            this.setState({
                prices: responseData
            })
            
        })
    }

    getOrder = () =>{
        console.log("getOrder")
        fetch("https://app-coins.ru/api/getOrder", {
            method: 'POST',
            headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
            body: qs.stringify(this.state.params)
        }).then((response) => response.json())
        .then((responseData) => {
            if (responseData.response === 1){
                if (this.loadedOrder != undefined && responseData.order.sdk_tasks != undefined && responseData.order.sdk_tasks.length > 0){
                    var summ = 0
                    for (let index = 0; index < this.loadedOrder.sdk_tasks.length; index++) {
                        const lastTask = this.loadedOrder.sdk_tasks[index];
                        const newTask = responseData.order.sdk_tasks[index];
                        if (lastTask.state == 0 && newTask.state == 1){
                            summ += lastTask.price
                        }
                    }
                    this.props.updateMoney(summ)
                }
                this.loadedOrder = responseData.order
                this.setState({
                    order: responseData.order,
                    task3 : responseData.order.with_review,
                    task4 : responseData.order.good_review_top,
                    task5 : responseData.order.open_3_day,
                    done: true,
                    hasOrder: true,
                })
                if (responseData.country != "UA" && responseData.order.with_review){
                    this.setState({isRedirect : true})
                }
                if (responseData.order.sdk_tasks != undefined){
                    this.setState({sdkTasks : responseData.order.sdk_tasks})
                }
            } else {
                this.setState({
                    done: true,
                    hasOrder: false,
                })
            }
            
        })
    }
    componentDidMount() {
        ym(86575405,'reachGoal','openTask')
        this.getOrder()
        this.getPriceConfig()
    }
    showErrorBanner = (title, btnText) =>{
        this.errorTitle = title
        this.errorBtn = btnText
        this.setState({isShowError : true}) 
    }
    successClicked = () =>{
        
    }
    closeBannerClicked = () =>{
        this.setState({isShowError : false})
    }
    completed1Task = () =>{
        this.setState({task2Enabled : true})
        console.log("completed1Task" )
        
    }
    check4Task(){
        if (this.state.task4) {
            this.setState({task4Enabled : true})
        } else if (this.state.task5){
            this.setState({task5Enabled : true})
        }
    }
    completed2Task = () =>{
        if(this.state.task3){
            this.setState({task3Enabled : true})
        } else {
            this.check4Task()
        }
        this.setState({isSdkTaskEnabled : true})
        console.log("completed2Task" )
        
    }
    completed3Task = () =>{
        this.check4Task()
        console.log("completed3Task" )
        
    }
    completed4Task = () =>{
        if (this.state.task5){
            this.setState({task5Enabled : true})
        }
        console.log("completed4Task" )
        
    }
    completed5Task = () =>{
        
        console.log("completed5Task" )
        
    }
    toggleSdkAppeal(isShowSdkAppeal, info){
        if (isShowSdkAppeal){
            this.setState({isShowSdkAppeal : isShowSdkAppeal, sdkAppealInfo : info})
        } else {
            this.setState({isShowSdkAppeal : isShowSdkAppeal})
        }
    }
    showErrorResponse = (title, btnText, onClick) =>{
        this.successClicked = () => {
            if (onClick != undefined){
                onClick()
            }
            this.setState({isShowError : false})
        }
        
        this.showErrorBanner(title, btnText)
    }
    testMethod = () => {
        if (window.firstTask != undefined){
            window.firstTask.onResume();
        }
        if (window.secondTask != undefined){
            window.secondTask.onResume();
        }
        if (window.thirdTask != undefined){
            window.thirdTask.onResume();
        }
        if (window.fouthTask != undefined){
            window.fouthTask.onResume();
        }
        if (window.fifthTask != undefined){
            window.fifthTask.onResume();
        }
       
    }
    setAppPosition = (appPosition, searchText) => {
        this.setState({appPosition : appPosition, searchText : searchText})
    }
    componentDidUpdate(prevProps){
        if (prevProps != this.props){
            var params = this.state.params
            params.timestamp = this.props.timestamp
            this.setState({params : params})
            console.log("task params = " + JSON.stringify(params))
        }
        
    }
    render() {
        if (!this.state.done){
               return(<Loader/>) 
        } else if(this.state.hasOrder){
            return (
                <div className="bot_48">
                    <div className="flex padding_16 p_bottask">
                        <div className="custom_icon_container">
                            <img className="custom_icon" src={(this.state.order == undefined || this.state.order.icon == undefined || this.state.order.icon == null) ? "" : this.state.order.icon.includes("http") ? this.state.order.icon : "https://" + this.state.order.icon} alt=""/>
                        </div>
                           
                        <div>
                            <p className="custom_task_name">{"<- ищите по этой иконке"}</p>
                            <p className="money_text custom_money_text top_price_0">+{this.state.order.price_one_install_employer}₽</p>
                        </div>
                    </div>
                    {this.state.isNewTask && <p className="task_name magring_text">На выполнение задания у вас есть 20 минут. Не покидайте эту страницу, пока не выполните все доступные пункты задания.</p>}
                    {this.state.isShowError && <ErrorBanner closeBannerClicked={this.closeBannerClicked} successClicked={this.successClicked} errorBtn={this.errorBtn} errorTitle={this.errorTitle}/>}
                    <FirstTask setAppPosition={this.setAppPosition} isRedirect={this.state.isRedirect} progress={this.progressArray} showErrorResponse={this.showErrorResponse} params={this.state.params} completedTask={this.completed1Task} task={this.state.order}/>
                    {this.state.task2 && <SecondTask updateMoney={this.props.updateMoney} progress={this.progressArray} isEnabled={this.state.task2Enabled} priceInstall={this.state.prices.MINIMAL_PRICE_EMPLOYER} showErrorResponse={this.showErrorResponse} params={this.state.params} completedTask={this.completed2Task} task={this.state.order}/>}
                    {this.state.task3 && <ThirdTask isRedirect={this.state.isRedirect} updateMoney={this.props.updateMoney} progress={this.progressArray} isEnabled={this.state.task3Enabled} priceReview={this.state.prices.REVIEW_PRICE_EMPLOYER} showErrorResponse={this.showErrorResponse} params={this.state.params} completedTask={this.completed3Task} task={this.state.order}/>}
                    {this.state.task4 && <FouthTask updateMoney={this.props.updateMoney} progress={this.progressArray} isEnabled={this.state.task4Enabled} priceReview={this.state.prices.GOOD_REVIEW_TOP_EMPLOYER} showErrorResponse={this.showErrorResponse} params={this.state.params} completedTask={this.completed4Task} task={this.state.order}/>}
                    {this.state.sdkTasks.length > 0 && <p style={{marginLeft : '16px', marginTop : '16px', marginBottom : '16px'}}>Интернет должен быть включен при выполнении следющих заданий. Иначе они не будут засчитаны!</p>}
                    {this.state.sdkTasks.length > 0 && this.state.sdkTasks.map((item, index) => (
                        <SdkTaskAndroid toggleSdkAppeal={this.toggleSdkAppeal} isEnabled={this.state.isSdkTaskEnabled || (this.progressArray && this.progressArray[0] == 1)} getOrder={this.getOrder} sdkTasks={this.state.sdkTasks} task={this.state.order} sdkTask={item} index={index} key={index}/>
                    ))}
                    {this.state.task5 && <FifthTask onBack={this.props.onBack} updateMoney={this.props.updateMoney} progress={this.progressArray} isEnabled={this.state.task5Enabled} modified={this.modified} priceThirdDay={this.state.prices.THIRD_DAY_PRICE_EMPLOYER} showErrorResponse={this.showErrorResponse} params={this.state.params} completedTask={this.completed5Task} task={this.state.order}/>}
                    <AppealTask searchText={this.state.searchText} appPosition={this.state.appPosition} progress={this.progressArray} params={this.state.params} task={this.state.order}/>
                    <SdkAppealTask toggleSdkAppeal={this.toggleSdkAppeal} isShowSdkAppeal={this.state.isShowSdkAppeal} sdkAppealInfo={this.state.sdkAppealInfo} params={this.state.params} task={this.state.order}/>

                    
                </div>
            )
        } else {
           return(<p className="padding_16">Заказ был удален</p>)
        }
         
    }
}
export default Task;
