import React from 'react';
import qs from 'querystring'
import {getRequestParams} from './custom_views/insta_custom_views/utils_android/CookieHelper'
import ExtraComment from './custom_views/insta_custom_views/ExtraComment';
import BaseEarnTask from './custom_views/insta_custom_views/BaseEarnTask';
import ExtraFollow from './custom_views/insta_custom_views/ExtraFollow';
import ExtraView from './custom_views/insta_custom_views/ExtraView';
import PhotoTask from './custom_views/insta_custom_views/PhotoTask';
import LoaderEarn from './custom_views/insta_custom_views/utils_android/LoaderEarnAndroid';
import EmptyTask from './custom_views/insta_custom_views/EmptyTaskAndroid';
import { ReCaptcha } from 'react-recaptcha-v3'
import FineDialog from './custom_views/insta_custom_views/FineDialog';
import insta_icon from '../pages_android/icons_android/instagram.svg'
import EarnCheckDialog from './custom_views/EarnCheckDialog';
class EarnInsta extends BaseEarnTask {
    constructor(props){
        super(props)
        this.state = {
            currentDescription : "",
            isLoading : true,
            currentExtraOrder : {},
            currentPhotoTask : {},
            isCurrentExtra : true,
            currentType : -1,
            extraOrdersCount : 0,
            isShowEmpty : false,
            isShowFineDialog : false,
            fineCoinsCount : 0,
            isShowCheckDialog : false
        }
        this.toogleFineDialog = this.toogleFineDialog.bind(this);
        this.toogleCheckDialog = this.toogleCheckDialog.bind(this);

    }
    
    componentDidMount(){
        this.getExtraOrder()
        setTimeout(this.timerFinish, 1000);
    }
    timerFinish = () => {
        const isShownCheckDialog = localStorage.getItem('isShownCheckDialog')

        this.setState({isShowCheckDialog : (isShownCheckDialog == undefined || isShownCheckDialog == null)})
        localStorage.setItem('isShownCheckDialog', 'isShownCheckDialog')

    }
    showLoader = () => {
        this.setState({isLoading : true})
    }
    hideLoader = () => {
        this.setState({isLoading : false})
    }
    loadNextOrder = (isLoadPhoto) => {
        if (isLoadPhoto){
            this.getPhotoTask()
        } else {
            this.getExtraOrder()
        }
        console.log("loadNextOrder")
    }
    getExtraOrder = () => {
        this.showLoader();
        var params = getRequestParams();
       //params.timestamp = this.state.timestamp
        console.log("params getExtraOrder = " + JSON.stringify(params));

        fetch("https://app-coins.ru/api/getExtraOrders", {
                    method: 'POST',
                    headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
                    body: qs.stringify(params)
                }, 6000).then((response) => response.json())
                .then((responseData) => {
                    if (responseData.order) {
                        this.setState({
                             currentExtraOrder : responseData,
                             isCurrentExtra : true,
                             isLoading : false,
                             currentType : responseData.order.type
                            })
                       
                        switch (responseData.order.type) {
        
                            case 0:
                                //followers
                                this.setState({currentDescription : this.FOLLOW_PHOTO_TASK_DESC})
                                if (responseData.order.user_name == undefined || responseData.order.user_name == "undefined") {
                                    this.skipOrder(true);
                                }
                                break;
                            case 1:
                                //views
                                this.setState({currentDescription : this.VIDEO_TASK_DESCRIPTION})
                                break;
                            case 2:
                                //comments
                                this.setState({currentDescription : this.COMMENT_PHOTO_TASK_DESC})
                                break;
                            default:
        
        
                        }
                      this.props.setMoney(responseData.count_money_r)
                    } else {
                        this.setState({currentExtraOrder : {}, isCurrentExtra : false})
                        this.getPhotoTask()
                    }
        
        
                    console.log("extra order = " + JSON.stringify(responseData));
                }).catch((e) => {
                    console.log("error = " + e);
                    this.getPhotoTask()
                })      
    }
    getPhotoTask = () => {
        this.showLoader();
        var params = getRequestParams();
        console.log("getPhotoTask params = " + JSON.stringify(params))
        fetch("https://app-coins.ru/api/getPhotoTask", {
                    method: 'POST',
                    headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
                    body: qs.stringify(params)
                }).then((response) => response.json())
                .then((responseData) => {
                    if (responseData.photo) {
                        this.setState({currentPhotoTask : responseData, isCurrentExtra: false, currentDescription : this.LIKE_PHOTO_TASK_DESC, currentType : 3})
                    } else {
                        this.showNoTasksView();
                    }
                    this.props.setMoney(responseData.count_money_r)
                    this.setState({isLoading : false})
                    console.log("getPhotoTask = " + JSON.stringify(responseData));
                })         
    }
    changeAcc = () => {
       // window.location.href = '/auth_insta?back=tasks&isAuth=1'
       this.props.openViewBack("auth_insta", "tasks")
    }
    showNoTasksView = () => {
        console.log("showNoTasksView")
        this.setState({isShowEmpty : true, isLoading : false, currentPhotoTask : {}})
    }   
    onOrderSkipped = () => {
        var skipOrders = this.state.extraOrdersCount + 1
        this.setState({extraOrdersCount : skipOrders})
        console.log("skipOrders count = " + skipOrders)
        return skipOrders
    }
    toogleFineDialog(){
        this.setState({isShowFineDialog : !this.state.isShowFineDialog})
    }
    showFinedDialog = (coinsCount) => {
        this.setState({isShowFineDialog : true, fineCoinsCount : coinsCount})
    }
    toogleCheckDialog(){
        this.setState({isShowCheckDialog : !this.state.isShowCheckDialog})
    }
    render() {
        return (
           this.props.activeView == "earn_insta" ? <section className="earn-money_section">
               <div className="task_checking_container">
                    <p onClick={this.toogleCheckDialog} className="task_checking_text">🤓 Как работает проверка заданий</p>
               </div>
                <p style={{marginTop : '18px'}} className="task_text">{this.state.currentDescription}</p>
                {this.state.currentExtraOrder.order && this.state.currentType == 2 && <ExtraComment isNeedInstaCaptcha={this.props.isNeedInstaCaptcha} showLoader={this.showLoader} loadNextOrder={this.loadNextOrder} isCurrentExtra={this.state.isCurrentExtra} currentExtraOrder={this.state.currentExtraOrder} hideLoader={this.hideLoader} onOrderSkipped={this.onOrderSkipped} extraOrdersCount={this.state.extraOrdersCount}/>}
                {this.state.currentExtraOrder.order && this.state.currentType == 0 && <ExtraFollow showFinedDialog={this.showFinedDialog} showLoader={this.showLoader} loadNextOrder={this.loadNextOrder} isCurrentExtra={this.state.isCurrentExtra} currentExtraOrder={this.state.currentExtraOrder} hideLoader={this.hideLoader} onOrderSkipped={this.onOrderSkipped} extraOrdersCount={this.state.extraOrdersCount}/>}
                {this.state.currentExtraOrder.order && this.state.currentType == 1 && <ExtraView isNeedInstaCaptcha={this.props.isNeedInstaCaptcha} showLoader={this.showLoader} loadNextOrder={this.loadNextOrder} isCurrentExtra={this.state.isCurrentExtra} currentExtraOrder={this.state.currentExtraOrder} hideLoader={this.hideLoader} onOrderSkipped={this.onOrderSkipped} extraOrdersCount={this.state.extraOrdersCount}/>}
                {this.state.currentPhotoTask.photo && this.state.currentType == 3 && <PhotoTask showLoader={this.showLoader} loadNextOrder={this.loadNextOrder} isCurrentExtra={this.state.isCurrentExtra} currentPhotoTask={this.state.currentPhotoTask} hideLoader={this.hideLoader} onOrderSkipped={this.onOrderSkipped} extraOrdersCount={this.state.extraOrdersCount}/>}
                {this.state.isShowEmpty && <EmptyTask showLoader={this.showLoader} loadNextOrder={this.loadNextOrder}/>}
                {this.state.isLoading && <LoaderEarn/>}
                {this.state.isShowFineDialog && <FineDialog toogleFineDialog={this.toogleFineDialog} coinsCount={this.state.fineCoinsCount}/>}
                {/* <div onClick={this.changeAcc} className="change_acc_insta">
                    <div className="center_change_acc">
                        <img style={{width : '12px'}} src={insta_icon}/>
                        <p className="change_acc_text">Сменить аккаунт</p>
                    </div>
                </div> */}
                <EarnCheckDialog isShowCheckDialog={this.state.isShowCheckDialog} toogleCheckDialog={this.toogleCheckDialog}/>

            </section>  : <div></div>
        );
    }
}
export default EarnInsta;