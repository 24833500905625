import React from 'react';
import ufoIcon from './insta_icons_tasks/ufo_android.png';

class EmptyTask extends React.Component {
    constructor(props){
        super(props)
        
    }
  
    updateOrders = () => {
        this.props.showLoader()
        this.props.loadNextOrder(false)
    }
    auth = () => {
        window.location.href = "/auth_insta?back=tasks&testReactJS=1"
    }
    render() {
        return (
            <div className="empty_tasks_container empty_container_insta">
                <div>
                            <img className="ufo_icon" src={ufoIcon} alt=""/>
                            <p className="empty_tasks_text text_cont_empty_new">На данный момент<br/>новых заданий нет</p>
                            {/* <button onClick={this.updateOrders} className="task_btn update_btn_empty">Обновить</button>
                            {this.props.isTiktok == undefined && <p onClick={this.auth} className="empty_task_insta">Теперь вы можете<span className="insta_change"> сменить свой Инстаграм аккаунт </span>и выполнить задания от его имени. При этом не забудьте поменять аккаунт в самом приложении Инстаграм.</p>} */}
                </div>
            </div>
        );
    }
}
export default EmptyTask;