import React from 'react';
import qs from 'querystring'
import { getHttpParams } from './insta_custom_views/utils_android/UrlHelperAndroid';
class SdkAppealTask extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            description : ""
        }
        this.descriptionChanged = this.descriptionChanged.bind(this)

    }
    
    showAndroidToast(toast) {
        if (typeof window.Android !== "undefined" && window.Android !== null) {
            window.Android.showToast(toast);
        }
    }
   
    hideAppeal = () => {
       this.props.toggleSdkAppeal(false, "")
    }
    
    componentDidMount(){
        this.hideAppeal()
    }
    descriptionChanged(event){
        if (event){
            var value = event.target.value
            this.setState({description : value})
        }
    }
    sendAppeal = () => {
        var params = {task_id : this.props.sdkAppealInfo.task_id, complaint : this.state.description}
        console.log("sendAppeal params = " + JSON.stringify(params))
        fetch("https://app-coins.ru/api/addComplaintToSDK", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            console.log("sendAppeal responseData = " + JSON.stringify(responseData))
            this.setState({description : ""})
        })
        this.showAndroidToast("Сообщение успешно отправлено")
        this.hideAppeal()
    }
    render() {
        return (
            <div className={this.props.isShowSdkAppeal ? 'appeal_container_bg_shown' : 'appeal_container_bg_hiden sdk_appeal_header'}>
                <div onClick={this.hideAppeal} className={this.props.isShowSdkAppeal ? 'animateAppealBgShow' : 'animateAppealBgHide sdk_appeal_header'}>

                </div>
                <div className={this.props.isShowSdkAppeal ? 'appeal_container_shown' : 'appeal_container_hide sdk_appeal_header'}>
                    <div className="appeal_header">
                        <p className="appeal_header_text">Сообщить об ошибке</p>
                    </div>
                    <div className="block padding_16">
                        <p className="sdk_task_appeal_title" dangerouslySetInnerHTML={{__html: "Ошибка на шаге: " + this.props.sdkAppealInfo.text}}/>
                        <p className="sdk_appeal_text">Ваше сообщение увидит создатель задания</p>
                        <input className="sdk_appeal_description" type="text" placeholder="Опишите суть ошибки" onChange={this.descriptionChanged} value={this.state.description}/>
                        <div className="sdk_appeal_line"/>
                        <div className="sdk_appeal_buttons">
                            <p onClick={this.hideAppeal} className="task_name appeal_text_task_money red_appeal_text">Отмена</p>
                            <button onClick={this.sendAppeal} disabled={this.state.description.length < 3}
                            className="task_btn">ОТПРАВИТЬ</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default SdkAppealTask;