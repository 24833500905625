import React from 'react';
class DropdownItem extends React.Component {
    constructor(props){
        super(props)
        
    }
    
    userPicked = () => {
        this.props.userPicked(this.props.user)
        console.log("user picked = " + this.props.user.username)
    }
    render() {
        return (
            <div onClick={this.userPicked} className="select__item">
                {/* <img className="select__item-img" src={this.props.user.profile_pic_url}/> */}
                <div>
                    <span className="select__item-name">{this.props.user.username}</span>
                    <span className="select__item-desc">{this.props.user.full_name}</span>
                </div>       
            </div>
        );
    }
}
export default DropdownItem;