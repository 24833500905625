import React from 'react';
import heart_fine from '../insta_custom_views/insta_icons_tasks/heart_fine.svg'
import { declension } from './utils_android/UrlHelperAndroid';
import { openUrl } from './utils_android/AndroidMethods';

class ScreenUploadInstruction extends React.Component {
    constructor(props){
        super(props)

    }
    closeTooltip = () =>{
        this.props.toggleScreenUpload()
    }
    openUplaod = () => {
        openUrl("https://ru.imgbb.com")
    }
    render(){
      
            return (
                <div className="fine_dialog_background">
                    <div className="fine_dialog instr_dialog">
                        <p className="instr_title">Инструкция по загрузке скриншотов</p>
                        <p className="instr_text">1. Загрузите скриншот по ссылке <span onClick={this.openUplaod} style={{color : "#03A0E3"}}>ru.imgbb.com</span> и вставьте ссылку на него в отчёт.<br/><br/>2. Нажмите на кнопку “Начать загрузку” и загрузите один или несколько скриншотов.<br/><br/>3. Установите автоудаление изображения через 1 месяц.<br/><br/>4. Нажмите на кнопку “Загрузка”.<br/><br/>5. Скопируйте ссылки на просмотр и вставьте их в текст вашего отчёта.</p>
                        <p onClick={this.closeTooltip} className="instr_btn hover">Закрыть</p>
                    </div>
                </div>
                    
            ); 
        
        
    }
}
export default ScreenUploadInstruction;
