/* eslint-disable eqeqeq */
export const AppsType = {
    android: "android",
    rustore: "rustore",
    ios: "ios",
}
export const getAppUrl = (type_os, bundle) => {
    var url = ""
    if (type_os == AppsType.android){
        url = AppsUrls.android + bundle
    } else if (type_os == AppsType.rustore){
        url = AppsUrls.rustore + bundle
    } else {
        url = AppsUrls.ios + bundle
    }
    return url
}
export const getSearchRequestAppUrl = (type_os, bundle, search_request) => {
    var url = ""
    if (type_os == AppsType.android){
        url = AppsSearchUrls.android + search_request + '&c=apps&hl=ru'
    } else if (type_os == AppsType.rustore){
        url = AppsSearchUrls.rustore + search_request
    } else {
        if (window.innerWidth >= 769) {
            url = AppsUrls.ios + bundle
        } else {
            url = AppsSearchUrls.ios + search_request
        }
    }
    return url
}

export const AppsUrls = {
    android : "https://play.google.com/store/apps/details?id=",
    rustore : "https://www.rustore.ru/catalog/app/",
    ios : "https://itunes.apple.com/ru/app/id"
}
export const AppsSearchUrls = {
    android : "https://play.google.com/store/search?q=",
    rustore : "https://www.rustore.ru/catalog/search?query=",
    ios : "https://search.itunes.apple.com/WebObjects/MZSearch.woa/wa/search?media=software&term="
}