import React from 'react';
import empty_image_insta from './insta_icons_tasks/empty_image_insta.svg'
import heart_insta_android from './insta_icons_tasks/heart_insta_android.svg'
import chevron_next_insta_android from './insta_icons_tasks/chevron_next_insta_android.svg'
import BaseEarnTask from './BaseEarnTask';


class PhotoTask extends BaseEarnTask {
    constructor(props){
        super(props)
        this.state = {
            currentDescription : "",
        }
        this.startTaskTime = -1
        window.photoTask = this
    }
    
    onResume(){
        if (this.startTaskTime != -1){
            this.props.showLoader()
            var currentTime = new Date().getTime() / 1000
            if (currentTime - 1 >= this.startTaskTime){
                this.checkFollowTask()
            }
        }
    }
    checkFollowTask = () => {
        setTimeout(() => this.instaLike(1), 500);
    }
    doTask = () => {
        this.startTaskTime = new Date().getTime() / 1000
        this.openUrl(this.props.currentPhotoTask.insta_url)
    }
    
    skip = () => {
        this.skipOrder(false, this.props.currentPhotoTask.photo._id)
    }
  
    render() {
        return (
            <div className="rate-content__item">
                    <div className="photo_task_container">
                        <img width="64" height="64" className="placeholder_task icon_64"
                            src={empty_image_insta} alt=""/>
                        <img className="image_task_holder" src={this.props.currentPhotoTask.photo.photo_path_standart}
                            alt="" width="360" height="360"/>
                        <img onClick={this.skip} src={chevron_next_insta_android} className="arrows__item arrows__item_next arrow_item_follower"></img>
                        <p onClick={this.appealClicked} className="appeal_text appeal_text_bg">Пожаловаться</p>
                    </div>


                    <div className="rate-content__actions">
                        <div onClick={this.doTask} className="btn-follow top_10">
                            <img className="btn_task_icon" src={heart_insta_android} alt=""/>
                            Лайкнуть
                        </div>
                        <div className="coins_rub_lashka rub_plashka">
                            <span
                                className="subscribe_follow_reward">+{this.props.currentPhotoTask.earn_money}₽</span>
                        </div>
                    </div>
            </div>
        );
    }
}
export default PhotoTask;
