import React from 'react';
import qs from 'querystring'
import { getRequestParams } from '../pages_android/custom_views/insta_custom_views/utils_android/CookieHelper';
import { getUrlParameter } from '../pages_android/custom_views/insta_custom_views/utils_android/UrlHelperAndroid';
import  arrow  from './icons_android/arrow_left_android.svg';
import  burger  from './icons_android/mobile_bar_android.svg';
import  x_close_android  from './icons_android/x_close_android.svg';
import { finish } from '../pages_android/custom_views/insta_custom_views/utils_android/AndroidMethods';
class NavbarAndroid extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            countMoney : 0.0,
            isBack : false
        }
        
    }
    sideButtonClicked = function(){
        //var backUrl = getUrlParameter("back")
        if (this.props.backView){
            //window.location.href = '/' + backUrl + "?testReactJS=1"
            this.props.onBack(this.props.backView)
        } else {
            // var overlay = document.createElement('button');

            // overlay.setAttribute('title', 'Закрыть меню');
            // overlay.className = 'overlay';
            // document.querySelector('.app').appendChild(overlay);
            // document.querySelector('.aside').style.left = '0';
            // overlay.addEventListener('click', function (e) {

            //     document.querySelector('.aside').removeAttribute('style');
            //     document.querySelector('.app').removeChild(overlay);
        
            // });
            this.props.toogleMenu()
        }
        
        
    }
    componentDidMount(){
        var backUrl = getUrlParameter("back")
        if (backUrl != undefined && backUrl != ""){
            this.setState({isBack : true})
        }
    }
    closeUpmob = () => {
        finish()
    }
    render() {
        return (
           <header style={this.props.isUpmob ? {background : "#3c525f"} : {background : "#01a862"}} className="header justify_content not_selectable">
                <div className="flex">
                    <img onClick={() => this.sideButtonClicked()} src={this.props.backView ? arrow : burger} className={this.props.backView ? 'header__mobile-menu_back' : 'header__mobile-menu_burger'}/>
                    {/* <button onClick={() => this.sideButtonClicked()} className={this.state.isBack ? 'header__mobile-menu_back' : 'header__mobile-menu_burger'}></button> */}
                    <p className="header_title">{this.props.isUpmob ? "Бонус" : "Smoozi"}</p>
                    <div className={this.props.isUpmob ? "block" : "not_display"}>
                        <p className="user_name money_nav_text header_title_16">{parseFloat(this.props.count_money_r).toFixed(2)}₽</p>
                    </div>
                    
                </div>
                <img onClick={this.closeUpmob} className={(!this.props.isUpmob || this.props.isClose) ? "close_nav" : "not_display"} src={x_close_android} alt=""/>
  
            </header>
        );
    }
}
export default NavbarAndroid;