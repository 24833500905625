export const getCookie = (name) => {
    return localStorage.getItem(name)
};
export const setCookie = (name,value) => {
    localStorage.setItem(name, value)
}
export const getRequestParams = () => {
     
        var requestParams = {
            'google_user_id': getCookie("google_user_id"),
            'type_os': "android",
            'sdk_v': "1.0"
        }
        if (getCookie("device_id") !== null && getCookie("device_id") !== undefined) {
            requestParams.device_id = getCookie("device_id")
        }
        if (getCookie("api_key") !== null && getCookie("api_key") !== undefined) {
            requestParams.api_key = getCookie("api_key")
        }
        //testing
        // var requestParams = {
        //     'google_user_id': "111721080232915624716",
        //     'device_id': "gsagasdag23g2gewag",
        //     'type_os': "android",
        //     'sdk_v': "1.0",
        //     'api_key': "upmob"
        // }
        if (getCookie("login654") !== null && getCookie("login654") !== undefined) {
            requestParams.instagram_user_name = getCookie("login654")
            requestParams.instagram_uid = getCookie("user_id")
        }
        
        if (getCookie("uniq_user_id") !== null && getCookie("uniq_user_id") !== undefined) {
            requestParams.easy_money_user_id = getCookie("uniq_user_id")
        }
        return requestParams;
    };

    export const getGoogleId = () => {
        return getCookie("google_user_id");
    };
    export const saveRegistrCookie = (response, device_id, uniq_user_id, api_key) => {
        setCookie('device_id', device_id);
        setCookie('google_user_id', response.google_user_id);
        if (uniq_user_id != undefined && uniq_user_id != ""){
            setCookie('uniq_user_id', uniq_user_id);
        }
        if (api_key != undefined && api_key != ""){
            setCookie('api_key', api_key);
        }
        
    };
    export const saveInstaCookie = (login654, user_id) => {
        setCookie('login654', login654);
        setCookie('user_id', user_id);
    };
    export const getInstaLoginCookie = () => {
       return getCookie('login654');
    };
    export const getInstaUseridCookie = () => {
        return getCookie('user_id');
     };
    export const saveBundle = (bundle) => {
        setCookie('bundle', bundle);
    };
    export const saveApiKey = (api_key) => {
        setCookie('api_key', api_key);
    };

    
    export const getBundle = () => {
        return getCookie("bundle");
    };
    export const getInstaLogin = () => {
        return getCookie("login654");
    };
    export const getIsUpmobKey = () => {
        return getCookie("api_key") == "upmob" || getCookie("api_key") == "lexus" || getCookie("api_key") == "QWT" || getCookie("api_key") == "QWT2";
    };
    export const isShowClose = () => {
        return getCookie("api_key") == "QWT" || getCookie("api_key") == "QWT2";
    };

    export const getRemovedOrders = () => {
        var orders = JSON.parse(localStorage.getItem('removedOrders'))
        if (orders == null || orders == undefined){
            orders = []
        }
        return orders
    };
    export const addRemovedOrder = (order_id) => {
        var removedOrders = getRemovedOrders()
        if (removedOrders == null || removedOrders == undefined){
            removedOrders = []
        }
        removedOrders.push(order_id)
        localStorage.setItem('removedOrders', JSON.stringify(removedOrders));
    };

    export const getStartedOrders = () => {
        var orders = JSON.parse(localStorage.getItem('startedOrders'))
        if (orders == null || orders == undefined){
            orders = []
        }
        return orders
    };
    export const addStartedOrder = (order_id) => {
        var startedOrders = getStartedOrders()
        if (startedOrders == null || startedOrders == undefined){
            startedOrders = []
        }
        startedOrders.push(order_id)
        localStorage.setItem('startedOrders', JSON.stringify(startedOrders));
    };

