import React from 'react';
import qs from 'querystring'
class AppealTask extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            appealNotFoundTask : "Не получается найти приложение в поиске",
            appNotLaunch : "Приложение не запускается",
            noMoneyForApp : "Не начисляются деньги за запуск приложения",
            noIcon : "Нет иконки",
            isAppealShown : false
        }
    }
    onAppealClick = (text) => {
        var params = this.props.params
        var app_url = "https://play.google.com/store/apps/details?id=" + this.props.task.bundle
        var search_url = "https://play.google.com/store/search?q=" + this.props.task.search_request + "&c=apps"
        params.order_id = this.props.task._id
        params.app_url = app_url
        params.search_request = encodeURI("https://play.google.com/store/search?q=" + this.props.searchText)
        params.progress = this.props.progress
        params.appPosition = this.props.appPosition
        // if (progressArray != null && progressArray != undefined){
        //     params.progressArray = progressArray
        // }
        // if (modified != null && modified != undefined) {
        //     params.install_date = modified
        // }
        params.is_app_installed = this.isAppInstalled(this.props.task.bundle)
        // params.lastResponse = lastResponse
        params.appeal_text = text
        console.log("appealTask params = " + JSON.stringify(params));


        fetch("https://app-coins.ru/api/appealTask", {
                method: 'POST',
                headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
                body: qs.stringify(params)
            }).then((response) => response.json())
            .then((responseData) => {
               
            })
        this.showAndroidToast("Жалоба отправлена")
        this.hideAppeal()
    }
    showAndroidToast(toast) {
        if (typeof window.Android !== "undefined" && window.Android !== null) {
            window.Android.showToast(toast);
        }
    }
    isAppInstalled(bundle) {
        if (typeof window.Android !== "undefined" && window.Android !== null) {
            return window.Android.isAppInstalled(bundle);
        } else {
            return false
        }
    }
    hideAppeal = () => {
        console.log("hideAppeal")
        this.setState({isAppealShown : false})
    }
    openAppeal = () => {
        this.setState({isAppealShown : true})
    }
    componentDidMount(){
        this.hideAppeal()
    }
    render() {
        return (
            <div className={this.state.isAppealShown ? 'appeal_container_bg_shown' : 'appeal_container_bg_hiden'}>
                <div onClick={this.hideAppeal} className={this.state.isAppealShown ? 'animateAppealBgShow' : 'animateAppealBgHide'}>

                </div>
                <div className={this.state.isAppealShown ? 'appeal_container_shown' : 'appeal_container_hide'}>
                    <div onClick={this.openAppeal} className="appeal_header">
                        <p className="appeal_header_text">Пожаловаться на задание</p>
                    </div>
                    <div className="block padding_16">
                        <p onClick={() => this.onAppealClick(this.state.appealNotFoundTask)} className="task_name appeal_text_task_money">
                            {this.state.appealNotFoundTask}
                        </p>
                        <p onClick={() => this.onAppealClick(this.state.appNotLaunch)} className="task_name appeal_text_task_money">{this.state.appNotLaunch}</p>
                        <p onClick={() => this.onAppealClick(this.state.noMoneyForApp)}  className="task_name appeal_text_task_money">{this.state.noMoneyForApp}</p>
                        <p onClick={() => this.onAppealClick(this.state.noIcon)}  className="task_name appeal_text_task_money">{this.state.noIcon}</p>
                        <p onClick={() => this.onAppealClick("Другое")} className="task_name appeal_text_task_money">Другое</p>
                        <p onClick={this.hideAppeal} className="task_name appeal_text_task_money red_appeal_text">Отмена</p>
                    </div>
                </div>
            </div>
        );
    }
}
export default AppealTask;