import React from 'react';
import ActiveTaskItem from './list_items/ActiveTaskItem';
import qs from 'querystring'
import {getRequestParams, getGoogleId} from './custom_views/insta_custom_views/utils_android/CookieHelper'
import Loader from './custom_views/insta_custom_views/utils_android/LoaderAndroid';
import ufoIcon from './custom_views/insta_custom_views/insta_icons_tasks/ufo_android.png';
import CustomTaskItem from './list_items/CustomTaskItem';

class FinishedTasksAndroid extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            //tasks: ((this.props.info != undefined && this.props.info.progress_orders != undefined) ? this.props.info.progress_orders : []),
            done : false,
            finishedYoutubeTasks : []
        }
    }
    componentDidMount(){
        // if (getGoogleId() == "115617333779596885662"){
        //     this.getMyActiveYoutube()
        // }
        this.getMyActiveYoutube()
        
    }
   
    getMyActiveYoutube = () => {
        var params = getRequestParams()
        fetch("https://app-coins.ru/api/getMyActiveYoutube", {
            method: 'POST',
            headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
            body: qs.stringify(params)
        }).then((response) => response.json())
        .then((responseData) => {
            if (responseData.response == 1 && responseData.proof_orders_youtube && responseData.proof_orders_youtube.length > 0){
                var activeTasks = []
                responseData.proof_orders_youtube.forEach(task => {
                    if ((task.state == 1 || task.state == -1) && task.order != null){
                        activeTasks.push(task)
                    }
                });
                this.setState({finishedYoutubeTasks : activeTasks})
                
            }
            this.setState({done : true})
        })
    }
    getFinishedTasks = () => {
        var finalTasks = []
        if (this.props.info != undefined && this.props.info.progress_orders != undefined){
            var tasks = this.props.info.progress_orders
            for (let index = 0; index < tasks.length; index++) {
                const element = tasks[index];
                element.category = "Завершено"
                if (!element.progressArray.includes(0)) {
                    if (element.progressArraySDK != undefined && element.progressArraySDK.length > 0){
                        if (!element.progressArraySDK.includes(0)){
                            finalTasks.push(element)
                        }
                    } else {
                        finalTasks.push(element)
                    }
                }
            }
            return finalTasks.reverse()
        } else {
            return []
        }
    }
    render() {
        const tasks = this.getFinishedTasks()
        if (!this.state.done){
            return(<Loader/>) 
        } else if(tasks.length > 0 || this.state.finishedYoutubeTasks.length > 0){
            return (
                <div>
                    <div className="flex justify_content pad_left_0">
                        <p className="task_name active_tasks_text">Завершенные задания</p>
                    </div>
                    {this.state.finishedYoutubeTasks.map((item, index) => (
                            <CustomTaskItem onTaskClicked={this.props.onTaskClicked} correction={item.correction} isActive={false} proof_order_id={item._id} orderState={item.state} proof_text={item.proof_text} task={item.order} index={index} key={index}
                            description_state={item.state == 1 ? "<p style='color : #2DB789'>Одобрено</p>" : (item.state == 2 ? "<p style='color : #E17373'>Нужно доработать</p>" : (item.state == -1 ? "<p style='color : #E17373'>Отклонено</p>" : (item.state == 0 ? "<p>На проверке</p>" :  (item.state == 3 ? "<p>Выполняется</p>" :  undefined))))}/>
                    ))}
                    {tasks.map((item, index) => (
                            <ActiveTaskItem backView="finished" onTaskClicked={this.props.onTaskClicked} isActive={false} task={item} key={index}/>
                    ))}
                </div>
            );
        } else {
            return (
                <div className="empty_tasks_container">
                    <div className="display_block">
                        <img className="ufo_icon" src={ufoIcon} alt=""/>
                        <p className="empty_tasks_text_new">На данный момент<br/>завершенных заданий нет</p>
                    </div>
                </div>
            )
        }
    }
}
export default FinishedTasksAndroid;